<template>
<!--  Подключать через   <search-bar class="acts__search-bar" :placeholder="searchBarPlaceholderText"
                  @getSearchInput="searchInput = $event"></search-bar>-->
  <div :class="inputFocused ? 'search-focus' : ''" class="search">
    <svg
      fill="none"
      height="35"
      viewBox="0 0 35 35"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        :stroke="inputFocused ? '#13387D' : '#818285'"
        cx="21.7317"
        cy="13.2683"
        r="10.2683"
        stroke-width="6"
      />
      <path
        :stroke="inputFocused ? '#13387D' : '#818285'"
        d="M3 32.0007L13.3961 22.7598"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="6"
      />
    </svg>
    <input
      class="search__input"
      :placeholder="placeholder ? placeholder : placeholderText"
      @blur="inputFocused = false"
      @focus="inputFocused = true"
      @input="$emit('getSearchInput', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      searchInput: "",
      inputFocused: false
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    placeholderText() {
      if (this.isEnglish) {
        return 'Search'
      } else {
        return 'Поиск'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.search {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 12px 9px 18px;
  background-color: #e7e7e8;
  border-radius: 10px;

  @media (max-width: $mobile-version) {
    padding: 4px 12px 4px 2px;
    border-radius: $borderRadiusMobile;
  }

  > svg {
    margin-right: 23px;

    @media (max-width: $mobile-version) {
      height: 18px;
      margin-right: 0;
    }
  }

  &-focus {
    background-color: $white-color;
    box-shadow: $boxShadowLight;
  }

  &__input {
    max-width: 100%;
    width: 1000px;
    display: inline-block;
    padding: 10px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;

    @media (max-width: $mobile-version) {
      width: 280px;
      padding: 0;
      font-size: 7px;
      line-height: 9px;
    }
  }

  &__input::placeholder {
    color: $grey-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;

    @media (max-width: $mobile-version) {
      font-size: 7px;
      line-height: 9px;
    }
  }
}
</style>