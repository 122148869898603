<template>
  <div class="acts">
    <div v-if="editItem && editSubsections" class="admin-input">
      <textarea v-model="actBlock.section.titleEnglish" rows="1" v-if="isEnglish"></textarea>
      <textarea v-model="actBlock.section.title" rows="1" v-else></textarea>
    </div>
    <div v-else class="acts__subtitle">
      <span v-if="isEnglish">{{ actBlock.section.titleEnglish }}</span>
      <span v-else> {{ actBlock.section.title }} </span>
      </div>
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus && editSubsections"
      :item-edited="editItem"
      :item-id="actBlock.section.id"
      :options="widgetOptionsDelete"
      @toggleEdit="editSubsection"
    ></admin-widget-edit-delete>

    <admin-widget-add-new
      v-if="checkContentManagerStatus && editActs"
      :options="widgetOptionsAdd"
      @addAct="addAct($event)"
    ></admin-widget-add-new>

    <div class="acts__no-results" v-if="searchInput && !filteredActs.length && !isEnglish">Результатов нет</div>
    <div class="acts__no-results" v-if="searchInput && !filteredActs.length && isEnglish">No results</div>
    <div class="acts__list">
      <acts-page-section-list-item
        v-for="(child, index) in filteredActs"
        :key="index"
        :act="child"
        :editActs="editActs"
        :parent-id="actBlock.section.id"
        :searchInput="searchInput"
        :section-id="sectionId"
        @deleteActItem="deleteAct($event)" />
    </div>

  </div>
</template>

<script>
import ActsPageSectionListItem from "@/components/pages/ActsPageSectionListItem";
import AdminWidgetAddNew from "@/components/admin/AdminWidgetAddNew";
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";
import request from "@/services/request";

export default {
  name: "ActsPageSectionItem",
  components: {
    AdminWidgetEditDelete,
    AdminWidgetAddNew,
    ActsPageSectionListItem
  },
  props: {
    searchInput: {
      type: String,
      default: ""
    },
    actBlock: {
      type: Object,
      default: () => {
      }
    },
    actsWithNoSearch: {
      type: Array,
      default: () => []
    },
    sectionId: {
      type: Number
    },
    editSubsections: {
      type: Boolean,
      default: false
    },
    editActs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editItem: false,
      searchResult: [],
      widgetOptionsAdd: {
        titleEnglish: "Add act",
        title: "Добавить нормативный акт",
        eventName: "addAct",
        inputs: [
          {
            label: "Название акта",
            numberOfRows: 5,
            vModelKeyName: "title",
            editorEnable: false
          },
          {
            label: "Название акта (Английская версия)",
            numberOfRows: 5,
            vModelKeyName: "titleEnglish",
            editorEnable: false
          },
          {
            label: "Описание акта",
            numberOfRows: 5,
            vModelKeyName: "description",
            editorEnable: false
          },
          {
            label: "Описание акта (Английская версия)",
            numberOfRows: 5,
            vModelKeyName: "descriptionEnglish",
            editorEnable: false
          },
          {
            label: "Источник публикации",
            numberOfRows: 5,
            vModelKeyName: "modalSource",
            editorEnable: true
          },
          {
            label: "Примечание к документу",
            numberOfRows: 7,
            vModelKeyName: "modalComment",
            editorEnable: true
          },
          {
            label: "Договаривающиеся стороны (при отсутствии оставить пустым)",
            numberOfRows: 7,
            vModelKeyName: "modalParties",
            editorEnable: true
          },
          {
            label: "Название документа (в модальном окне)",
            numberOfRows: 5,
            vModelKeyName: "modalTitle",
            editorEnable: true
          }
        ]
      },
      widgetOptionsDelete: {
        mutationName: "",
        swapItems: {
          mutationNames: [],
          type: ""
        }
      }
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    // filteredActs() {
    //     let searchResult = [...this.actBlock.docs];
    //     searchResult.forEach(doc => {
    //       if (doc.title) doc.title = doc.title?.replaceAll(/<\/*mark>/g, "");
    //       if (doc.description) doc.description = doc.description?.replaceAll(/<\/*mark>/g, "");
    //
    //     });
    //     if (this.searchInput) {
    //       const search = this.searchInput?.toLowerCase();
    //       searchResult = searchResult.filter((item) => {
    //         return (
    //           item.title?.toLowerCase().indexOf(search) !== -1 ||
    //           item.description?.toLowerCase().indexOf(search) !== -1
    //         );
    //       });
    //
    //       const searchTextRegExp = new RegExp(this.searchInput, "ig");
    //       searchResult.forEach(doc => {
    //         doc.title = doc.title?.replaceAll(searchTextRegExp, `<mark>${this.searchInput?.toLowerCase()}</mark>`);
    //         doc.description = doc.description?.replaceAll(searchTextRegExp, `<mark>${this.searchInput?.toLowerCase()}</mark>`);
    //       });
    //       return searchResult;
    //     } else {
    //       return this.actsWithNoSearch;
    //     }
    // },
    filteredActs() {
      if (this.actBlock.docs === null || this.actBlock.docs.length === 0) return [];
      let searchResult = [...this.actBlock.docs];
      searchResult.forEach(doc => {
        if (doc.title) doc.title = doc.title?.replace(/<\/*mark>/g, "");
        if (doc.description) doc.description = doc.description?.replace(/<\/*mark>/g, "");
        if (doc.titleEnglish) doc.titleEnglish = doc.titleEnglish?.replace(/<\/*mark>/g, "");
        if (doc.descriptionEnglish) doc.descriptionEnglish = doc.descriptionEnglish?.replace(/<\/*mark>/g, "");

      });
      if (this.searchInput) {
        const search = this.searchInput?.toLowerCase();
        searchResult = searchResult.filter((item) => {
          return (
              item.title?.toLowerCase().indexOf(search) !== -1 ||
              item.description?.toLowerCase().indexOf(search) !== -1 ||
              item.titleEnglish?.toLowerCase().indexOf(search) !== -1 ||
              item.descriptionEnglish?.toLowerCase().indexOf(search) !== -1
          );
        });

        searchResult.forEach(doc => {
          const searchText = new RegExp(this.searchInput, "gi");
          var matches = doc.title?.match(searchText);
          if (matches !== null) {
            matches = this.unique(matches);
            matches.forEach(mt => {
              const searchTextRegExp =
                  new RegExp(mt, "g");
              if (doc.title) doc.title = doc.title?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
            })
          }
          matches = doc.description?.match(searchText);
          if (matches !== null) {
            matches = this.unique(matches);
            console.log(matches)
            matches.forEach(mt => {
              const searchTextRegExp =
                  new RegExp(mt, "g");
              if (doc.description) doc.description = doc.description?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
            })
          }

          matches = doc.titleEnglish?.match(searchText);
          if (matches !== null) {
            matches = this.unique(matches);
            console.log(matches)
            matches.forEach(mt => {
              const searchTextRegExp =
                  new RegExp(mt, "g");
              if (doc.titleEnglish) doc.titleEnglish = doc.titleEnglish?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
            })
          }

          matches = doc.descriptionEnglish?.match(searchText);
          if (matches !== null) {
            matches = this.unique(matches);
            console.log(matches)
            matches.forEach(mt => {
              const searchTextRegExp =
                  new RegExp(mt, "g");
              if (doc.descriptionEnglish) doc.descriptionEnglish = doc.descriptionEnglish?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
            })
          }
        });
        return searchResult;
      } else {
        return this.actsWithNoSearch;
      }

    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  },
  methods: {
    unique(arr) {
      let result = [];
      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      return result;
    },
    async addAct(event) {
      event.newObj.id = null;
      event.newObj.section = {};
      event.newObj.section.id = this.actBlock.section.id;
      const res = await request({
        endpoint: `/api/acts`,
        body: event.newObj,
        method: "POST"
      });
      this.actBlock.docs.push(res.data);
      if (res.status !== 200) {
        alert("Ошибка добавления");
      }
      //console.log("ADD_ACT", res.data);
    },
    async editSubsection() {
      if (!this.editItem) {
        this.editItem = true;
      } else {
        const res = await request({
          endpoint: `/api/sections`,
          body: this.actBlock.section,
          method: "PUT"
        });
        this.editItem = false;
        if (res.status !== 200) {
          alert("Ошибка редактирования");
        }
      }
    },
    deleteAct(event) {
      this.actBlock.docs = this.actBlock.docs.filter(act => act.id !== event.actId);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.acts {
  margin-bottom: 45px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__subtitle {
    margin-bottom: 27px;
    color: $primary-color;
    font-weight: 800;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 10px;
      font-size: $fontSizeMobileXS;
      line-height: 10px;
    }
  }

  &__no-results {
    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: $fontSizeMobileXS;
    }
  }
}

.admin {
  &-input {
    max-width: 100%;
    width: 600px;

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>