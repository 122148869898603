<template>
  <div class="toggle-block">
    <div class="toggle-block__flex">
      <div
        v-if="!editItem"
        class="toggle-block__title"
        @click="toggleDescription"
        v-html="item.title"
      ></div>
      <div :class="showDescription ? 'toggle-block__btn-toggle-active' : ''"
           class="toggle-block__btn-toggle"
           @click="toggleDescription">
        <svg fill="none" viewBox="0 0 47 26"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L23.5 23.5L45 2"
                stroke-linecap="round"
                stroke-width="3" />
        </svg>
      </div>

      <admin-widget-edit-delete-new
        v-if="checkContentManagerStatus"
        :item-edited="editItem"
        :options="widgetOptions"
        @deleteBlock="deleteBlock"
        @toggleEdit="editBlock"
      />
    </div>

    <div v-if="editItem" class="widget-input-label">
      <editor
        v-model="item.title"
        :apiKey="getEditorKey"
        :init="getEditorOptions"
      />
    </div>
    <div v-if="editItem" class="widget-input-label">
      <editor
        v-model="item.description"
        :apiKey="getEditorKey"
        :init="getEditorOptions"
      />
    </div>

    <div
      v-else
      :style="{display: showDescription ? 'block' : 'none'}"
      class="toggle-block__description"
      v-html="item.description"
    >
    </div>
  </div>
</template>

<script>
import AdminWidgetEditDeleteNew from "@/components/admin/AdminWidgetEditDeleteNew";
import Editor from "@tinymce/tinymce-vue";
import request from "@/services/request";

export default {
  name: "ToggleBlockListItem",
  components: {
    AdminWidgetEditDeleteNew,
    Editor

  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDescription: false,
      editItem: false,
      widgetOptions: {
        eventName: "deleteBlock",
        swapItems: ""
      }
    };
  },
  watch: {
    "$route.path"() {
      this.showDescription = false;
    }
  },
  computed: {
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "500";
      options.width = "1050";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  },
  methods: {
    async toggleDescription() {
      if (!this.showDescription) {
        this.showDescription = true;
        await request({
          endpoint: `/api/toggleBlocks/counter/${this.item.id}`,
          method: "GET"
        });
      } else {
        this.showDescription = false;
      }
    },
    async editBlock() {
      if (!this.editItem) {
        this.editItem = true;
      } else {
        let objToSend = { ...this.item };
        const res = await request({
          endpoint: `/api/toggleBlocks`,
          body: objToSend,
          method: "PUT"
        });
        this.editItem = false;
        if (res.status !== 200) {
          alert("Ошибка редактирования");
        }
      }
    },
    async deleteBlock() {
      const res = await request({
        endpoint: `/api/toggleBlocks/${this.item.id}`,
        method: "DELETE"
      });
      this.$emit("deleteBlock", { blockId: this.item.id });
      if (res.status !== 200) {
        alert("Ошибка удаления");
      }
      //console.log("DELETE BLOCK", res.data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.toggle-block {
  min-width: 1140px;
  padding: 15px 30px 18px 0;
  border-bottom: 1px solid $grey-color;

  //@media (max-width: $mobile-version) and (orientation: portrait) {
  //  padding: 10px 5px 10px 0;
  //}

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    margin-right: 40px;
    font-weight: 500;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  margin-right: 10px;
    //  font-size: $fontSizeMobileM;
    //}
  }

  &__btn-toggle {
    width: 43px;

    &:hover > svg > path {
      stroke: $primary-color;
    }

    > svg {
      width: 47px;

      > path {
        stroke: $grey-color;
      }
    }

    &-active {
      transform: scaleY(-1);

      > svg > path {
        stroke: $primary-color;
      }
    }
  }

  &__description {
    padding: 25px 100px 0 0;
    line-height: 18px;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  font-size: $fontSizeMobileS;
    //  padding-right: 0;
    //  line-height: 13px;
    //}
  }
}

.admin {

  &-input {
    max-width: 100%;
    width: 950px;
    padding-top: 20px;

    > textarea {
      width: 100%;
      margin-bottom: 5px;
      padding: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

.widget-input-label {
  margin-bottom: 10px;
}

</style>