<template>
  <div>
    <warning-phone-rotate />
    <main-loader v-if="!toggleNumberBlocksLoadingStatus" />
    <div v-else>
      <page-header />
      <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
      <admin-widget-add v-if="checkContentManagerStatus" :options="widgetOptions" />
      <div class="toggle-number-block" v-if="getToggleNumberBlocks">
        <toggle-number-block-list-item
          v-for="(infoBlock, index) in getToggleNumberBlocks.items"
          :key="index"
          :block-number="index"
          :info-block="infoBlock"
          :counter="clickCounterForPath.filter(block => block.id === infoBlock.id)[0]"
        />
      </div>
    </div>
  </div>

</template>

<script>
import ToggleNumberBlockListItem from "@/components/pages/ToggleNumberBlockListItem";
import AdminButtonsBlock from "@/components/admin/AdminButtonsBlock";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";
import PageHeader from "@/components/PageHeader";
import MainLoader from "@/components/MainLoader";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";
import request from "@/services/request";

export default {
  name: "ToggleNumberBlockList",
  components: {
    WarningPhoneRotate,
    ToggleNumberBlockListItem,
    AdminButtonsBlock,
    AdminWidgetAdd,
    PageHeader,
    MainLoader
  },
  data() {
    return {
      adminButtonsOptions: {
        actionNameSave: "sendToggleNumberBlocks",
        actionNameCancel: "downloadToggleNumberBlocks"
      },
      widgetOptions: {
        title: "Добавить блок",
        mutationName: "addToggleNumberBlockItem",
        inputs: [
          {
            label: "Заголовок блока",
            numberOfRows: 2,
            vModelKeyName: "title"
          },
          {
            label: "Заголовок блока (Английская версия)",
            numberOfRows: 2,
            vModelKeyName: "titleEng"
          },
          {
            label: "Текст блока",
            numberOfRows: 5,
            vModelKeyName: "description",
            editorEnable: true,
            editorHeight: "500"
          },
          {
            label: "Текст блока (Английская версия)",
            numberOfRows: 5,
            vModelKeyName: "descriptionEng",
            editorEnable: true,
            editorHeight: "500"
          }
        ]
      },
      clickCounterForPath: []
    };
  },
  mounted() {
    this.$store.dispatch("downloadToggleNumberBlocks");
    this.getCounter()
  },
  watch: {
    '$route.path'() {
      this.getCounter()
    },
  },
  computed: {
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    getCurrentPath() {
      return this.$route.path;
    },
    getToggleNumberBlocks() {
      window.scrollTo(0, 0);
      const currentBlock = this.$store.getters.getToggleNumberBlocks.filter(block => block.path === this.getCurrentPath);
      return currentBlock[0];
    },
    toggleNumberBlocksLoadingStatus() {
      return this.$store.getters.getToggleNumberBlocksLoadingStatus;
    }
  },
  methods: {
    async getCounter() {
      // Получает данные по всем кликам на блоки
      const res = await request({
        endpoint: `/api/category`,
        method: "GET"
      });
      let counterData = res.data;
      this.clickCounterForPath = counterData.filter(block => block.path === this.getCurrentPath.slice(1));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.toggle-number-block {
  padding: 0 10px 0 10px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }
}

</style>