<template>
  <div>
    <page-header :show-current-page-name="true" ></page-header>
    <main-loader v-if="loadingStatus" />
    <div
      :class="cardsNumberDividedBySix ? 'cards__grid-three' : 'cards__grid-two'"
      :style="{opacity: loadingStatus ? 0 : 1}"
      class="cards"
    >
      <card-list-item
        v-for="(card, index) in getCardList"
        :key="index"
        :card="card"
        @checkImageLoaded="checkImagesLoaded"
      ></card-list-item>
    </div>
  </div>
</template>

<script>
import CardListItem from "@/components/pages/CardListItem";
import PageHeader from "@/components/PageHeader";
import MainLoader from "@/components/MainLoader";

export default {
  name: "CardList",
  components: {
    MainLoader,
    CardListItem,
    PageHeader
  },
  data() {
    return {
      imagesLoaded: [],
      loadingStatus: true
    };
  },
  watch: {
    imagesLoaded() {
      this.loadingStatus = this.imagesLoaded.length !== this.getCardList.length;
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    getCardList() {
      const currentPath = this.$route.path;
      let cardList;
      this.$store.getters.getNavSections.map(nav => {
        nav.sections.map(section => {
          if (section.link === currentPath) {
            cardList = section.items;
          } else if (nav.link === currentPath) {
            cardList = nav.sections;
          }
        });
      });
      this.deleteImagesArray();
      return cardList;
    },
    cardsNumberDividedBySix() {
      return this.getCardList.length % 6 === 0 || this.getCardList.length >= 7;
    }
  },
  methods: {
    checkImagesLoaded(event) {
      this.imagesLoaded.push(event);
    },
    deleteImagesArray() {
      this.imagesLoaded = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.cards {
  width: 100%;
  display: grid;
  justify-items: stretch;
  align-items: center;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
  }

  &__grid-three {
    grid-template-columns: repeat(3, 1fr);
    gap: 70px 31px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      gap: 15px;
    }
  }

  &__grid-two {
    grid-template-columns: repeat(2, 1fr);
    gap: 70px 85px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      gap: 15px;
    }
  }
}


</style>