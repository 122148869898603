import { render, staticRenderFns } from "./ActsPage.vue?vue&type=template&id=c050fc54&scoped=true&"
import script from "./ActsPage.vue?vue&type=script&lang=js&"
export * from "./ActsPage.vue?vue&type=script&lang=js&"
import style0 from "./ActsPage.vue?vue&type=style&index=0&id=c050fc54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c050fc54",
  null
  
)

export default component.exports