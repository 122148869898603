<template>
  <div class="page">
    <div class="page-title">
      Запрашиваемая страница не существует
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage"
};
</script>


<style lang="scss" scoped>
@import "src/assets/styles/variables";

.page {
  padding: 20% 0;

  &-title {
    color: $primary-color;
    font-size: $fontSizeXXL;
    font-weight: 700;
  }
}
</style>