<template>
  <div class="contacts">
<!--    Контакты пока приходят вместе с навигацией, так как выводятся в меню вместе с разделами, нужно сделать отдельные эндпоинты и базу-->
    <div class="contacts__all">
    <a v-if="!isEnglish" class="contacts__link" href="/">Главная</a>
    <a v-if="isEnglish" class="contacts__link" href="/">Home</a>

    <img src="@/assets/images/breadcrumb-divider.svg" class="bread-divider">
    <div v-if="!isEnglish" class="contacts__cont">Контакты</div>
    <div v-if="isEnglish" class="contacts__cont">Contacts</div>
    </div>
    <div v-if="!isMobile && !isEnglish" class="contacts__title">Контакты</div>
    <div v-if="isMobile && !isEnglish" class="contacts__title">Контакты</div>

    <div v-if="!isMobile && isEnglish" class="contacts__title">Contacts</div>
    <div v-if="isMobile && isEnglish" class="contacts__title">Contacts</div>

    <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
    <admin-widget-add v-if="checkContentManagerStatus" :options="widgetOptions"></admin-widget-add>
    <contacts-page-list v-for="(contact, index) in getContacts" :key="index" :contact="contact" />
  </div>
</template>

<script>
import ContactsPageList from "@/components/pages/ContactsPageList";
// import PageHeader from "@/components/PageHeader";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";
import AdminButtonsBlock from "@/components/admin/AdminButtonsBlock";

export default {
  name: "ContactsPage",
  components: {
    AdminButtonsBlock,
    AdminWidgetAdd,
    ContactsPageList,
    // PageHeader
  },
  data() {
    return {
      adminButtonsOptions: {
        actionNameSave: "sendNavSections",
        actionNameCancel: "downloadNavSections"
      },
      widgetOptions: {
        title: "Добавить контакт",
        mutationName: "addContact",
        inputs: [
          {
            label: "Номер телефона, адрес почты или соцсети*",
            numberOfRows: 1,
            vModelKeyName: "mainInfo"
          },
          {
            label: "Номер телефона, адрес почты или соцсети* (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "mainInfoEng"
          },
          {
            label: "Дополнительная информация (при отсутствии оставить пустым)",
            numberOfRows: 1,
            vModelKeyName: "additionalInfo"
          },
          {
            label: "Дополнительная информация (Английская версия) (при отсутствии оставить пустым)",
            numberOfRows: 1,
            vModelKeyName: "additionalInfoEng"
          },
          {
            label: "Рабочие часы (при отсутствии оставить пустым)",
            numberOfRows: 1,
            vModelKeyName: "workHours"
          },
          {
            label: "Рабочие часы (Английская версия) (при отсутствии оставить пустым)",
            numberOfRows: 1,
            vModelKeyName: "workHoursEng"
          },
          {
            label: "Тип соц. сети",
            vModelKeyName: "icon",
            selectEnable: true,
            selectItems: ["vk", "whatsapp", "telegram", "odnoklassniki"],
          }
        ]
      }
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getContacts() {
      let contacts = [];
      this.$store.getters.getNavSections.map(item => {
        if (item.link === "/contacts") {
          contacts = item.sections;
        }
      });
      return contacts;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.contacts {
  min-width: $containerWidth;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: $smPhoneWidth;
  }
&__title {
  color: rgb(131, 6, 21);
  font-size: 36px;
  font-weight: 800;
  line-height: 45px;
  margin-bottom: 30px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    color: rgb(131, 6, 21) !important;
    margin-bottom: 13px;
    font-size: $fontSizeMobileL;
    line-height: 15px;
  }
}
}

.contacts__link {
  display: inline;
  justify-content: flex-start;
  align-items: flex-end;
  color: #818285;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 34px;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    font-size: 8px;
  }
}

.contacts__link:hover {
  color: $primary-color;
}

.contacts__cont {
  color: #000000;
  cursor: default;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  display: inline;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    font-size: 8px;
  }
}

.bread-divider {
  margin-left: 7px;
  margin-right: 7px;
  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-top: 3px;
    margin-left: 4px;
    margin-right: 4px;
    height: 10px;
  }
}

.contacts__all {
  margin-bottom: 35px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-bottom: 15px;
  }
}
</style>