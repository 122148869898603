<template>
  <div>

    <page-header></page-header>
<!--        <div v-if="!isAuthorised">-->
<!--          <router-link to="/auth">-->
<!--            <div class="not-auth__btn">-->
<!--              <span v-if="isEnglish">Sign in</span>-->
<!--              <span v-else>Вход в личный кабинет</span>-->
<!--            </div>-->
<!--          </router-link>-->
<!--        </div>-->
    <main-loader v-if="!eventsLoaded"/>
    <div v-else class="events">
      <div class="events__filter">
        <div
            :class="futureEventsFilter === 'future' ? 'events__filter-item-active' : ''"
            class="events__filter-item"
            @click="changeFilterType('future')"
        >
          Планируемые
        </div>
        <div
            :class="futureEventsFilter === 'past'  ? 'events__filter-item-active' : ''"
            class="events__filter-item"
            @click="changeFilterType('past')"
        >
          Прошедшие
        </div>
      </div>

      <search-bar class="events__search-bar" placeholder="Поиск по названию мероприятия, дате и времени его проведения"
                  @getSearchInput="searchInput = $event"></search-bar>

      <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions"/>
      <admin-widget-add v-if="checkContentManagerStatus" :options="widgetOptions"/>
      <div v-if="!futureEventsFilter">
        <div v-if="eventList.length" class="events__list">
          <event-list-item v-for="(event, index) in eventList" :key="index" :event-data="event"/>
        </div>
        <div
            v-else-if="!searchInput && !eventList.length"
            class="events__list-empty"
        >Прошедших мероприятий нет
        </div>
      </div>
      <div v-if="futureEventsFilter">
        <div v-if="eventList.length" class="events__list">
          <event-list-item v-for="(event, index) in eventList" :key="index" :event-data="event"/>
        </div>
        <div
            v-else-if="!searchInput && !eventList.length"
            class="events__list-empty"
        >В настоящее время нет запланированных мероприятий
        </div>
      </div>
      <div v-if="searchInput && !eventList.length" class="events__list-empty">
        <span>Поиск не дал результатов</span>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SearchBar from "../SearchBar";
import EventListItem from "./EventListItem";
import AdminButtonsBlock from "../admin/AdminButtonsBlock";
import AdminWidgetAdd from "../admin/AdminWidgetAdd";
import MainLoader from "../MainLoader";

export default {
  name: "EventList",
  components: {
    MainLoader,
    AdminWidgetAdd,
    AdminButtonsBlock,
    EventListItem,
    SearchBar,
    PageHeader
  },
  data() {
    return {
      searchInput: "",
      futureEventsFilter: 'future',
      firstLoad: true,
      adminButtonsOptions: {
        actionNameSave: "sendEventList",
        actionNameCancel: "downloadEventList"
      },
      widgetOptions: {
        title: "Добавить событие",
        mutationName: "addEventItem",
        inputs: [
          {
            label: "Заголовок блока",
            numberOfRows: 2,
            vModelKeyName: "title",
            selectEnable: true,
            selectItems: ["Вебинар", "Конференция", "Семинар"]
          },
          {
            label: "Текст блока",
            numberOfRows: 5,
            vModelKeyName: "text",
            editorEnable: true,
            editorHeight: "500"
          },
          {
            label: "Дата и время",
            numberOfRows: 5,
            vModelKeyName: "datetime",
            datePicker: true
          }
        ]
      }
    };
  },
  mounted() {
    this.$store.dispatch("downloadEventList");
  },
  computed: {
    vuexEvents() {
      return this.$store.getters.getEventList
    },
    eventList() {
      //   const events = [...this.$store.getters.getEventList];
      //   if (this.checkContentManagerStatus) {
      //     return events.sort((a, b) => b.datetime - a.datetime);
      //   } else {
      //     const currentTime = Date.now();
      //     if (events.length) {
      //       let filteredEvents = [...events];
      //
      //       filteredEvents.forEach(item => {
      //         if (item.text) item.text = item.text.replaceAll(/<\/*mark>/g, "");
      //       });
      //       if (this.searchInput) {
      //         const search = this.searchInput.toLowerCase();
      //         filteredEvents = filteredEvents.filter((event) => {
      //           return (
      //             event.text?.toLowerCase().indexOf(search) !== -1 ||
      //             moment(event.datetime).locale("ru").format("L").indexOf(search) !== -1
      //           );
      //         });
      //
      //         const searchTextRegExp = new RegExp(this.searchInput, "gi");
      //         filteredEvents.forEach(event => {
      //           event.text = event.text?.replaceAll(searchTextRegExp, `<mark>${this.searchInput}</mark>`);
      //         });
      //
      //         const futureEvents = events.filter(event => event.datetime > currentTime);
      //         const pastEvents = filteredEvents.filter(event => event.datetime <= currentTime);
      //
      //
      //         futureEvents.sort((a, b) => a.datetime - b.datetime);
      //         pastEvents.sort((a, b) => b.datetime - a.datetime);
      //
      //         if (this.futureEventsFilter && futureEvents.length) {
      //           return futureEvents;
      //         } else if (this.futureEventsFilter && !futureEvents.length) {
      //           if (this.firstLoad) {
      //             this.changeFilterType("past");
      //             return pastEvents;
      //           } else {
      //             return [];
      //           }
      //         } else {
      //           return pastEvents;
      //         }
      //       } else {
      //
      //         // const futureEvents = events.filter(event => event.datetime > currentTime);
      //         // const pastEvents = events.filter(event => event.datetime <= currentTime);
      //
      //         const futureEvents = filteredEvents.filter(event => {
      //           if (true === event.active) {
      //             return event;
      //           } else if(event.datetime >  currentTime) {
      //             return event;
      //           }
      //         });
      //
      //         const pastEvents = filteredEvents.filter(event => {
      //           if ( false === event.active) {
      //             return event;
      //           } else if(event.datetime <= currentTime) {
      //             return event;
      //           }
      //         });
      //
      //         // const pastEvents = events.filter(event => event.datetime <= currentTime);
      //
      //         futureEvents.sort((a, b) => a.datetime - b.datetime);
      //         pastEvents.sort((a, b) => b.datetime - a.datetime);
      //
      //         if (this.futureEventsFilter && futureEvents.length) {
      //           return futureEvents;
      //         } else if (this.futureEventsFilter && !futureEvents.length) {
      //           if (this.firstLoad) {
      //             this.changeFilterType("past");
      //             return pastEvents;
      //           } else {
      //             return [];
      //           }
      //         } else {
      //           return pastEvents;
      //         }
      //       }
      //     } else {
      //       return [];
      //     }
      //   }
      // },

      const events = this.vuexEvents;

      if (events.length === 0) return [];
        let filteredEvents = [...events];
        filteredEvents.forEach(item => {
          if (item.text) item.text = item.text.replaceAll(/<\/*mark>/g, "");

        });

      if (this.searchInput) {
        const search = this.searchInput?.toLowerCase();
        filteredEvents = filteredEvents.filter((item) => {
          return (
              item.text?.toLowerCase().indexOf(search) !== -1
          )
        });

        filteredEvents.forEach(item => {
          const searchText = new RegExp(this.searchInput, "gi");
          var matches = item.text?.match(searchText);
          if (matches !== null) {
            matches = this.unique(matches);
            console.log(matches)
            matches.forEach(mt => {
              const searchTextRegExp =
                  new RegExp(mt, "g");
              if (item.text) item.text = item.text?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
            })
          }
          // matches = item.eventTime?.match(searchText);
          // if (matches !== null) {
          //   matches = this.unique(matches);
          //   console.log(matches)
          //   matches.forEach(mt => {
          //     const searchTextRegExp =
          //         new RegExp(mt, "g");
          //     if (item.eventTime) item.eventTime = item.eventTime?.replace(searchTextRegExp, `<mark>${mt}</mark>`);
          //   })
          // }
        });

        return filteredEvents;
      }

      const eventspastfut = {
        future: {
          filterFunc: (event) => {
            if (true === event.active) {
              return event;
            }
          },
          sortFunc: (a, b) => a.datetime - b.datetime
        },
        past: {
          filterFunc: (event) => {
            if (false === event.active) {
              return event;
            }
          },
          sortFunc: (a, b) => b.datetime - a.datetime,
        },
      }
      // this.futureEventsFilter === "future"
      const config = eventspastfut[this.futureEventsFilter];
      return events.filter(config.filterFunc).sort(config.sortFunc);

    },
    eventsLoaded() {
      return this.$store.getters.getEventListLoadingStatus;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  },
  methods: {
    changeFilterType(type) {
      this.firstLoad = false;
      this.futureEventsFilter = type /*=== "future"*/;
    },
    unique(arr) {
      let result = [];
      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str);
        }
      }
      return result;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.events {
  position: relative;

  &__search-bar {
    margin-bottom: 44px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 25px;
    }
  }

  &__filter {
    position: absolute;
    top: -70px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      top: -28px;
    }

    &-item {
      padding: 13px 15px;
      color: $grey-color;
      font-size: 18px;
      font-weight: 800;
      background-color: #e7e7e8;
      border-radius: 7px;
      cursor: pointer;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        padding: 5px 10px;
        font-size: $fontSizeMobileS;
        border-radius: $borderRadiusMobile;
      }

      &:last-child {
        margin-left: 15px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          margin-left: 8px;
        }
      }

      &:hover {
        color: $primary-color;
        background-color: $white-color;
        //box-shadow: $boxShadowLight;
      }

      &-active {
        color: $primary-color;
        background-color: $white-color;
        //box-shadow: $boxShadowLight;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 54px 85px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 25px;
    }

    &-empty {
      padding-top: 40px;
      font-size: $fontSizeXL;
      font-weight: 700;
      line-height: 45px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        padding-top: 20px;
        font-size: $fontSizeMobileM;
        font-weight: 700;
        line-height: 15px;
      }
    }
  }

}

//.not-auth {
//  &__btn {
//    width: 525px;
//    height: 310px;
//    padding: 28px 33px;
//    color: $black-color;
//    font-weight: 700;
//    font-size: 24px;
//    background-color: $white-color;
//    background-image: url("../../assets/images/logo-login.svg");
//    background-repeat: no-repeat;
//    border-radius: $borderRadius;
//    box-shadow: $boxShadow;
//    cursor: pointer;
//
//    @media (max-width: $mobile-version) and (orientation: portrait) {
//      margin: 0 auto;
//    }
//
//    &:hover {
//      box-shadow: $boxShadowHover;
//
//      @media (max-width: $mobile-version) and (orientation: portrait) {
//        box-shadow: $boxShadowMobile;
//      }
//    }
//
//    @media (max-width: $mobile-version) and (orientation: portrait) {
//      width: 260px;
//      height: 175px;
//      padding: 17px 0 0 19px;
//      color: $primary-color;
//      font-size: $fontSizeMobileL;
//      font-weight: 800;
//      background-size: 300px;
//      background-position: -15px -5px;
//      border-radius: $borderRadiusMobile;
//      box-shadow: $boxShadowMobile;
//    }
//  }
//}
</style>