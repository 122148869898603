<template>
  <div>
    <warning-phone-rotate />
    <page-header></page-header>
<!--    <div v-if="!isAuthorised">-->
<!--      <router-link to="/auth">-->
<!--        <div class="not-auth__btn">-->
<!--          <span v-if="isEnglish">Sign in</span>-->
<!--          <span v-else>Вход в личный кабинет</span>-->
<!--        </div>-->
<!--      </router-link>-->
<!--    </div>-->
    <div>
      <main-loader v-if="!blockList" />
      <div v-else>
        <admin-widget-add-new
          v-if="checkContentManagerStatus"
          :options="widgetOptions"
          @addBlock="addBlock($event)"
        ></admin-widget-add-new>
        <div class="toggle-block-list" v-if="getToggleBlocks">
          <toggle-block-list-item
            v-for="(item, index) in blockList"
            :key="index"
            :item="item"
            @deleteBlock="deleteBlock($event)"
          ></toggle-block-list-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ToggleBlockListItem from "@/components/pages/ToggleBlockListItem";
import AdminWidgetAddNew from "@/components/admin/AdminWidgetAddNew";
import MainLoader from "@/components/MainLoader";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";
import request from "@/services/request";

export default {
  name: "ToggleBlockList",
  components: {
    WarningPhoneRotate,
    PageHeader,
    AdminWidgetAddNew,
    ToggleBlockListItem,
    MainLoader
  },
  data() {
    return {
      blockList: [],
      widgetOptions: {
        title: "Добавить блок",
        eventName: "addBlock",
        inputs: [
          {
            label: "Заголовок",
            numberOfRows: 5,
            vModelKeyName: "title",
            editorEnable: true,
            editorHeight: "500"
          },
          {
            label: "Описание",
            numberOfRows: 8,
            vModelKeyName: "description",
            editorEnable: true,
            editorHeight: "500"
          }
        ]
      }
    };
  },
  mounted() {
    this.getToggleBlocks();
  },
  computed: {
    currentPath() {
      return this.$route?.path.slice(1);
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    isAuthorised() {
      return this.$store.getters.getAuthData;
    }
  },
  methods: {
    async getToggleBlocks() {
      window.scrollTo(0, 0);
      const res = await request({
        endpoint: `/api/toggleBlocks/${this.currentPath}`,
        method: "GET"
      });
      //console.log("GET BLOCKS", res.data);
      this.blockList = res.data;
    },
    async addBlock(event) {
      event.newObj.toggleBlockSection = {}
      event.newObj.toggleBlockSection.path = this.currentPath
      if (!this.blockList.length) {
        const sections = await request({
          endpoint: `/api/toggleBlocks/sections`,
          method: "GET"
        });
        const sectionId = sections.data.find(section => section.path === this.currentPath).id
        event.newObj.toggleBlockSection.id = sectionId
      } else {
        event.newObj.toggleBlockSection.id = this.blockList[0].toggleBlockSection.id
      }

      const res = await request({
        endpoint: `/api/toggleBlocks`,
        body: event.newObj,
        method: "POST"
      });
      this.blockList.push(res.data);
      if (res.status !== 200 && res.status !== 201) {
        alert("Ошибка добавления");
      }
      //console.log("ADD BLOCK", res);
    },
    deleteBlock(event) {
      this.blockList = this.blockList.filter(block => block.id !== event.blockId);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.toggle-block-list {
  margin-top: -15px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }
}

.not-auth {
  &__btn {
    width: 525px;
    height: 310px;
    padding: 28px 33px;
    color: $black-color;
    font-weight: 700;
    font-size: 24px;
    background-color: $white-color;
    background-image: url("../../assets/images/logo-login.svg");
    background-repeat: no-repeat;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin: 0 auto;
    }

    &:hover {
      box-shadow: $boxShadowHover;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        box-shadow: $boxShadowMobile;
      }
    }

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 260px;
      height: 175px;
      padding: 17px 0 0 19px;
      color: $primary-color;
      font-size: $fontSizeMobileL;
      font-weight: 800;
      background-size: 300px;
      background-position: -15px -5px;
      border-radius: $borderRadiusMobile;
      box-shadow: $boxShadowMobile;
    }
  }
}

</style>