<template>
  <div class="acts__section">
    <main-loader v-if="!acts" />
    <div v-else>
      <page-header></page-header>
      <search-bar class="acts__search-bar" :placeholder="searchBarPlaceholderText"
                  @getSearchInput="searchInput = $event"></search-bar>
      <div class="admin__buttons" v-if="checkContentManagerStatus">
        <div class="admin__button" @click="startEditSections">
          {{ editSections ? "Принять изменения" : "Разделы" }}
        </div>
        <div class="admin__button" @click="startEditSubsections">
          {{ editSubsections ? "Принять изменения" : "Подразделы" }}
        </div>
        <div class="admin__button" @click="startEditActs">
          {{ editActs ? "Принять изменения" : "Акты" }}
        </div>
      </div>

      <admin-widget-add-new
        v-if="checkContentManagerStatus && editSections"
        :options="widgetOptions"
        @addSection="addActSection($event)"
      ></admin-widget-add-new>

      <div v-if="!acts.children.length" class="section__temp-title">
        <span v-if="currentPath === 'pisma'">В настоящий момент актуальных разъяснительных писем нет</span>
        <span v-else>Раздел находится в процессе разработки</span>
      </div>
      <div v-else class="section__list">
        <acts-page-section
          v-for="(child, index) in acts.children"
          :key="index"
          :searchInput="searchInput"
          :act-block="child"
          :editActs="editActs"
          :editSections="editSections"
          :editSubsections="editSubsections"
        ></acts-page-section>
      </div>
    </div>
  </div>
</template>

<script>
import ActsPageSection from "@/components/pages/ActsPageSection";
import PageHeader from "@/components/PageHeader";
import AdminWidgetAddNew from "../admin/AdminWidgetAddNew";
import SearchBar from "@/components/SearchBar";
import MainLoader from "@/components/MainLoader";
import request from "@/services/request";

export default {
  name: "ActsPage",
  components: {
    MainLoader,
    SearchBar,
    AdminWidgetAddNew,
    //AdminButtonsBlockNew,
    PageHeader,
    ActsPageSection
  },
  data() {
    return {
      acts: null,
      searchInput: "",
      editSections: false,
      editSubsections: false,
      editActs: false,
      adminButtonsOptions: {
        actionNameSave: "sendActs",
        actionNameCancel: "reloadActs"
      },
      widgetOptions: {
        title: "Добавить раздел",
        eventName: "addSection",
        inputs: [
          {
            label: "Название раздела",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Название раздела (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "titleEnglish"
          }
        ]
      }
    };
  },
  mounted() {
    this.getActs();
  },
  watch: {
    '$route.path'() {
      this.acts = null
      this.getActs();
    }
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    currentPath() {
      return this.$route.path.slice(1)
    },
    searchBarPlaceholderText() {
      if (this.currentPath === 'pisma') {
        if (this.isEnglish) {
          return 'Search by name and details of the letter'
        } else {
          return 'Поиск по названию и реквизитам письма'
        }
      } else {
        if (this.isEnglish) {
          return 'Search by name and details of the document'
        } else {
          return 'Поиск по названию и реквизитам документа'
        }
      }
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
  },
  methods: {
    async getActs() {
      window.scrollTo(0, 0);
      const currentPageName = this.$route?.path.slice(1);
      const res = await request({
        endpoint: `/api/acts/section/${currentPageName}`,
        method: "GET"
      });
      //console.log("GET_ACTS", res.data);
      this.acts = res.data;
    },
    async addActSection(event) {
      event.newObj.id = null
      event.newObj.path = null
      event.newObj.parent = this.acts.section.title
      event.newObj.parentId = this.acts.section.id
      const res = await request({
        endpoint: `/api/sections`,
        body: event.newObj,
        method: "POST"
      });
      const objToPush = {
        section: res.data,
        docs: [],
        children: []
      }
      this.acts.children.push(objToPush)
      if (res.status !== 200) {
        alert('Ошибка добавления')
      }
      //console.log("ADD_SECTION", res.data);
    },
    startEditSections() {
      if (!this.editSections) {
        this.editSections = true;
        this.editSubsections = false;
        this.editActs = false;
      } else {
        this.editSections = false;
      }
    },
    startEditSubsections() {
      if (!this.editSubsections) {
        this.editSections = false;
        this.editSubsections = true;
        this.editActs = false;
      } else {
        this.editSubsections = false;
      }
    },
    startEditActs() {
      if (!this.editActs) {
        this.editSections = false;
        this.editSubsections = false;
        this.editActs = true;
      } else {
        this.editActs = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.acts {
  &__search-bar {
    margin-bottom: 45px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 12px;
    }
  }

  &__section {
    .admin {
      &__buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 30px;
      }

      &__button {
        min-width: 160px;
        padding: 10px 15px;
        font-size: $fontSizeL;
        text-align: center;
        background-color: #e7e7e8;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          color: $white-color;
          background-color: $primary-color;
        }
      }
    }

    .section {
      &__temp-title {
        padding: 20vh 0;
        font-size: $fontSizeXXL;
        font-weight: 700;
        line-height: 46px;
      }
    }
  }
}

</style>