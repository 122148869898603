<template>
  <div>
    <warning-phone-rotate />
    <main-loader v-if="!actData"></main-loader>
    <div class="act" v-else>
      <div class="act__btn-return" @click="$router.go(-1)">
        Назад
      </div>
      <div class="act__search-hint">Нажмите Ctrl + F, чтобы начать поиск по документу</div>

      <admin-widget-edit-delete
        v-if="checkContentManagerStatus"
        :item-edited="editItem"
        :options="widgetOptions"
        @toggleEdit="editAct"
      />

      <div class="act__title" v-html="actData.title"></div>
      <div v-if="checkContentManagerStatus && editItem">
        <editor
          v-model="actData.text"
          :apiKey="getEditorKey"
          :init="getEditorOptions"
        />
      </div>
      <div v-else>
        <div class="act__text" v-html="actData.text"></div>
      </div>

    </div>
  </div>

</template>

<script>
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";
import Editor from "@tinymce/tinymce-vue";
//import AdminButtonsBlock from "@/components/admin/AdminButtonsBlock";
import request from "@/services/request";
import MainLoader from "@/components/MainLoader";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";
//import SearchBar from "@/components/SearchBar";

export default {
  name: "ActTextPage",
  components: {
    WarningPhoneRotate,
    MainLoader,
    //SearchBar,
    //AdminButtonsBlock,
    AdminWidgetEditDelete,
    Editor
  },
  props: {
    actId: {
      type: Number
    }
  },
  data() {
    return {
      actData: null,
      searchInput: "",
      editItem: false,
      widgetOptions: {
        mutationName: "",
        swapItems: {
          mutationNames: [],
          type: ""
        }
      },
      adminButtonsOptions: {
        actionNameSave: "sendActs",
        actionNameCancel: "downloadActs"
      }
    };
  },
  mounted() {
    this.getActData();
  },
  computed: {
    // getActData() {
    //   window.scrollTo(0,0)
    //   const initialData = this.$store.getters.getSelectedActData;
    //   if (this.searchInput) {
    //     let newData = { ...initialData };
    //     const searchText = new RegExp(this.searchInput, "gi");
    //     newData.text = newData.text.replace(searchText, `<mark>${this.searchInput}</mark>`);
    //     return newData;
    //   } else {
    //     return initialData;
    //   }
    // },
    getMainActsPagePath() {
      return "/" + this.$route.params.name;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "800";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  },
  methods: {
    async getActData() {
      window.scrollTo(0, 0);
      const currentActId = this.$route?.path.split("/")[2];
      const res = await request({
        endpoint: `/api/acts/${currentActId}`,
        method: "GET"
      });
      //console.log("GET_ACT_TEXT", res.data);
      this.actData = res.data;
    },
    async editAct() {
      if (!this.editItem) {
        this.editItem = true;
      } else {
        //console.log(this.actData);
        const res = await request({
          endpoint: `/api/acts`,
          body: this.actData,
          method: "PUT"
        });
        this.editItem = false;
        this.actData = res.data;
        if (res.status !== 200) {
          alert("Ошибка редактирования");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.act {
  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }

  &__btn {
    &-return {
      position: relative;
      margin-bottom: 30px;
      padding-left: 25px;
      color: $grey-color;
      font-weight: 700;
      cursor: pointer;

      > a:active {
        color: $primary-color;
      }

      &::before {
        content: url("../../assets/images/breadcrumb-divider.svg");
        position: absolute;
        top: 1px;
        left: 8px;
        color: $grey-color;
        transform: scaleX(-1);
      }

      &:hover, &:hover &::before {
        color: $primary-color;
        font-weight: 600;
      }
    }
  }

  &__search-hint {
    position: absolute;
    top: 20px;
    right: 0;
    color: $grey-color;
    font-size: $fontSizeMain;
    font-weight: bold;
    cursor: default;

    @media (max-width: $mobile-version) {
      display: none;
    }

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }
  }

  &__title {
    color: $primary-color;
    font-weight: 700;
    font-size: $fontSizeXXL;
  }
}
</style>