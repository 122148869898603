<template>
  <div class="contact__item">
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus"
      :item-edited="editItem"
      :item-id="contact.id"
      :options="widgetOptions"
      @toggleEdit="editItem = !editItem"
    ></admin-widget-edit-delete>
    <a
      :href="getContactLink + contact.mainInfo"
      :target="contactType === 'social' ? '_blank' : '_self'"
      class="contact__item-link"
      rel="noopener noreferrer nofollow"
    >
      <div class="contact__item-flex">
        <div v-if="!editItem" class="contact__item-icon">
          <svg v-if="contact.icon === 'tel'" fill="none" viewBox="0 0 23 23"
               xmlns="http://www.w3.org/2000/svg">
            <path
              class="fill"
              d="M5.23438 7.10327C5.32248 10.3694 7.16953 13.3781 8.08204 14.4742L10.4813 13.3256C9.22267 11.8468 8.08204 8.94403 8.08204 8.63724C8.08204 8.33044 9.78119 8.13378 10.0644 7.66179C10.3476 7.18981 10.3161 6.85155 9.78119 4.8692C9.35326 3.28332 8.47011 3.27493 8.08204 3.46897C5.93606 3.93466 5.28944 6.08588 5.23438 7.10327Z"
              fill="black" />
            <path
              class="fill"
              d="M13.5439 18.8292C10.4909 17.6651 8.26313 14.9265 7.53083 13.7026L9.40867 11.8187C10.3877 13.4958 12.7493 15.5328 13.0388 15.6344C13.3283 15.7359 14.0762 14.1976 14.6154 14.0866C15.1545 13.9755 15.4633 14.1172 17.1569 15.278C18.5117 16.2067 18.2274 17.0429 17.9158 17.3448C16.7662 19.2158 14.5222 19.114 13.5439 18.8292Z"
              fill="black" />
            <circle class="stroke" cx="11.5" cy="11.5" r="10.8" stroke="black" stroke-width="1.4" />
          </svg>
          <svg v-else-if="contact.icon === 'email'" fill="none" viewBox="0 0 23 23"
               xmlns="http://www.w3.org/2000/svg">
            <rect class="stroke" height="10" rx="1.5" stroke="black" transform="matrix(-1 0 0 1 18 6)" width="14"
                  x="-0.5" y="0.5" />
            <path
              class="stroke"
              d="M4.55566 8.19922L10.6865 11.938C11.3482 12.3416 12.1835 12.3265 12.8302 11.8992L18.4307 8.19922"
              stroke="black" />
            <circle class="stroke" cx="11.5" cy="11.5" r="10.8" stroke="black" stroke-width="1.4" />
          </svg>
          <svg v-else-if="contact.icon === 'telegram'" fill="none" viewBox="0 0 478.87 478.87"
               xmlns="http://www.w3.org/2000/svg">
            <path class="stroke"
                  d="M103.43,243.51,359.24,135.24a7,7,0,0,1,9.6,7.69l-42.2,230.92a11.92,11.92,0,0,1-18.72,7.49l-64.27-46.75L200.74,377.5a5.9,5.9,0,0,1-9.79-2.37l-28.7-89.38-59.16-28.52A7.53,7.53,0,0,1,103.43,243.51Z"
                  fill="none" stroke="black" stroke-miterlimit="10"
                  stroke-width="27px"
                  transform="translate(-10.56 -10.31)" />
            <path class="fill"
                  d="M185.22,361.75c0-1.63-11.36-83.87-11.36-83.87L322,184.32a2.7,2.7,0,0,1,3.32,4.23L206.33,303.31l-8.66,67.1Z"
                  fill="black"
                  transform="translate(-10.56 -10.31)" />
            <circle class="stroke" cx="239.44" cy="239.44" fill="none" r="225.94"
                    stroke="black" stroke-miterlimit="10" stroke-width="27px" />
          </svg>
          <svg v-else-if="contact.icon === 'instagram'" class="fill" viewBox="0 0 78.94 78.75" x="0px"
               xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" y="0px">
            <path d="M39.46,20.1c10.79,0.02,19.3,8.8,19.25,19.33c-0.05,10.63-8.73,19.21-19.35,19.15C28.86,58.52,20.18,50,20.22,39.27
		C20.25,28.62,28.88,20.09,39.46,20.1z M39.44,51.83c7.03-0.03,12.48-5.73,12.49-12.46c0.01-6.77-5.44-12.5-12.47-12.51
		c-7.05-0.01-12.52,5.72-12.5,12.53C26.99,46.1,32.39,51.8,39.44,51.83z" />
            <path class="fill" d="M53.54,18.91c-0.01-2.83,2.35-5.16,5.19-5.15c2.7,0.01,5.16,2.19,5.14,5.19c-0.01,2.96-2.45,5.2-5.26,5.15
		C55.75,24.05,53.51,21.67,53.54,18.91z" />
            <path class="stroke" d="M58.29,74.13H20.63c-8.82,0-15.96-7.15-15.96-15.96V20.51c0-8.82,7.15-15.96,15.96-15.96h37.66
		c8.82,0,15.96,7.15,15.96,15.96v37.66C74.26,66.98,67.11,74.13,58.29,74.13z" fill="none" stroke="black"
                  stroke-miterlimit="10" stroke-width="9" />
          </svg>
          <svg v-else-if="contact.icon === 'odnoklassniki'" class="fill" viewBox="0 0 78.03 78.03" x="0px"
               xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" y="0px">
            <path d="M0.21,70.71c-0.01-0.04-0.02-0.08-0.02-0.12C0.12,70.02,0.06,69.45,0,68.88c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52c0-0.51,0-1.02,0-1.53c0-0.51,0-1.01,0-1.52
	c0-0.51,0-1.02,0-1.53c0.03-0.51,0.07-1.01,0.1-1.52c0.02-0.1,0.04-0.2,0.05-0.29c0.01-0.03,0.02-0.07,0.03-0.1
	c0.21-0.86,0.5-1.68,0.88-2.48C1.99,3.29,3.3,1.99,4.97,1.06c0.8-0.37,1.62-0.67,2.48-0.88c0.03-0.01,0.07-0.02,0.1-0.03
	c0.1-0.02,0.2-0.04,0.29-0.05C8.36,0.07,8.86,0.03,9.37,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0c0.51,0,1.01,0,1.52,0c0.51,0,1.02,0,1.53,0
	c0.57,0.06,1.14,0.12,1.71,0.19c0.04,0.01,0.08,0.02,0.12,0.02c0.51,0.16,1.01,0.32,1.52,0.48c0.33,0.15,0.66,0.3,0.98,0.46
	c0.35,0.23,0.71,0.46,1.06,0.7c0.2,0.17,0.4,0.33,0.61,0.5c0.34,0.35,0.67,0.69,1.01,1.04c0.17,0.23,0.34,0.47,0.52,0.7
	c0.2,0.32,0.4,0.64,0.59,0.96c-0.02,0.12,0.06,0.19,0.12,0.27c0.02,0.15,0.08,0.27,0.18,0.38c0.03,0.23,0.12,0.44,0.22,0.64
	c0.29,1,0.46,2.01,0.5,3.05c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53
	c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53
	c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53
	c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53
	c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53
	c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53
	c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53
	c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53c0,0.51,0,1.02,0,1.53c-0.04,0.51-0.04,1.02,0,1.53
	c-0.08,1.04-0.25,2.06-0.58,3.05c-0.19,0.41-0.37,0.83-0.56,1.24c-0.16,0.27-0.32,0.53-0.48,0.8c-0.24,0.3-0.47,0.61-0.71,0.91
	c-0.27,0.27-0.54,0.54-0.82,0.82c-0.3,0.24-0.61,0.47-0.91,0.71c-0.27,0.16-0.53,0.32-0.8,0.48c-0.41,0.19-0.83,0.37-1.24,0.56
	c-0.99,0.34-2.01,0.5-3.05,0.58c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0
	c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0
	c-0.51-0.04-1.02-0.04-1.53,0c-0.51,0-1.02,0-1.53,0c-0.51-0.04-1.02-0.04-1.53,0c-1.04-0.04-2.05-0.21-3.05-0.5
	c-0.19-0.11-0.39-0.18-0.61-0.21c-0.12-0.09-0.25-0.17-0.41-0.19C5.22,77.06,5.15,76.99,5.03,77c-0.99-0.59-1.91-1.27-2.7-2.12
	c-0.17-0.2-0.33-0.4-0.5-0.61c-0.23-0.35-0.46-0.71-0.7-1.06c-0.15-0.33-0.3-0.66-0.46-0.98C0.53,71.73,0.37,71.22,0.21,70.71z
	M25.85,17.31c-0.17,0.25-0.31,0.52-0.4,0.81c-0.06,0.09-0.14,0.18-0.12,0.3c-0.29,0.51-0.44,1.07-0.59,1.63
	c-0.19,0.52-0.3,1.07-0.39,1.62c-0.23,1.49-0.33,2.99-0.03,4.49c-0.03,0.39,0.09,0.76,0.19,1.13c0.01,0.06,0.02,0.13,0.04,0.19
	c0.14,0.66,0.28,1.32,0.6,1.93c0.11,0.48,0.33,0.91,0.58,1.33c0.1,0.28,0.23,0.55,0.42,0.78c0.1,0.28,0.28,0.5,0.45,0.74
	c0.31,0.53,0.67,1.02,1.09,1.46c0.03,0.03,0.05,0.05,0.08,0.08c0.42,0.57,0.93,1.05,1.46,1.5c0.06,0.05,0.12,0.1,0.19,0.14
	c0.39,0.42,0.86,0.74,1.35,1.04c0.18,0.14,0.36,0.3,0.59,0.36c0.31,0.26,0.66,0.43,1.03,0.58c0.32,0.19,0.67,0.34,1.02,0.46
	c1.17,0.52,2.38,0.85,3.66,0.97c0.43,0.12,0.88,0.14,1.33,0.11c0.51,0.05,1.02,0.04,1.52-0.01c0.52,0,1.03-0.03,1.53-0.18
	c1.14-0.14,2.22-0.43,3.26-0.93c0.2-0.01,0.36-0.1,0.51-0.21c0.47-0.19,0.93-0.38,1.32-0.7c0.38-0.14,0.69-0.4,1.02-0.63
	c0.57-0.36,1.1-0.77,1.56-1.27c0.49-0.39,0.92-0.85,1.29-1.36c0.11-0.06,0.18-0.15,0.21-0.27c0.34-0.33,0.61-0.73,0.83-1.15
	c0.63-0.85,1.1-1.77,1.42-2.78c0.41-0.83,0.6-1.72,0.76-2.62c0.01-0.1,0.03-0.2,0.04-0.3c0.27-1.21,0.25-2.44,0.16-3.67
	c-0.03-0.44-0.06-0.89-0.18-1.32c-0.02-0.49-0.16-0.96-0.31-1.43c-0.13-0.57-0.31-1.12-0.57-1.64c-0.12-0.4-0.28-0.79-0.52-1.13
	c-0.04-0.15-0.08-0.29-0.21-0.39c-0.57-1.14-1.31-2.16-2.24-3.05c-0.2-0.28-0.45-0.51-0.72-0.72c-0.24-0.28-0.51-0.52-0.82-0.7
	c-0.2-0.22-0.44-0.38-0.7-0.52c-0.41-0.33-0.85-0.63-1.33-0.84c-0.27-0.21-0.59-0.34-0.91-0.46c-0.9-0.47-1.86-0.78-2.85-1
	c-0.27-0.05-0.53-0.16-0.81-0.14c-1.59-0.35-3.19-0.36-4.79-0.08c-0.52,0.04-1.03,0.13-1.53,0.3c-0.54,0.08-1.04,0.27-1.53,0.48
	c-0.28,0.08-0.56,0.16-0.81,0.34c-0.41,0.15-0.8,0.33-1.15,0.59c-0.41,0.14-0.74,0.39-1.08,0.64c-0.21,0.15-0.42,0.31-0.63,0.46
	c-0.39,0.22-0.71,0.52-1.03,0.83c-0.27,0.27-0.55,0.55-0.82,0.82c-0.25,0.16-0.39,0.41-0.57,0.62c-0.29,0.27-0.5,0.59-0.71,0.91
	c-0.37,0.47-0.71,0.96-0.95,1.51C25.93,17.07,25.87,17.18,25.85,17.31L25.85,17.31z M25.14,41.95c-0.07-0.01-0.13-0.02-0.2-0.03
	c-0.66-0.19-1.31-0.16-1.94,0.11c-0.54,0.1-0.97,0.39-1.34,0.78c-0.07,0.07-0.14,0.14-0.2,0.21c-0.3,0.25-0.51,0.57-0.63,0.94
	c-0.35,0.68-0.49,1.38-0.29,2.14c0.08,0.86,0.47,1.58,1.12,2.13c0.82,0.7,1.73,1.26,2.69,1.74c1.05,0.65,2.16,1.18,3.32,1.61
	c0.45,0.24,0.92,0.41,1.41,0.53c0.14,0.05,0.28,0.1,0.42,0.15c0.36,0.15,0.73,0.29,1.12,0.34c0.1,0.14,0.35,0.01,0.44,0.28
	c-0.21,0.2-0.45,0.41-0.68,0.64c-2.96,2.95-5.91,5.91-8.86,8.87c-0.49,0.5-0.88,1.07-0.96,1.79c-0.15,0.36-0.14,0.74-0.08,1.12
	c0,0.08,0.01,0.15,0.01,0.23c0.03,0.09,0.05,0.19,0.08,0.28c0.31,1.41,1.19,2.28,2.53,2.72c0.5,0.19,1.01,0.19,1.53,0.12
	c0.63-0.05,1.19-0.28,1.68-0.68c0.22-0.14,0.44-0.29,0.58-0.52c0.33-0.27,0.64-0.57,0.9-0.9c0.24-0.17,0.45-0.38,0.62-0.62
	c0.38-0.31,0.71-0.65,1.02-1.02c0.17-0.09,0.29-0.22,0.39-0.38c0.38-0.38,0.76-0.76,1.15-1.15c0.16-0.1,0.28-0.22,0.38-0.38
	c0.36-0.36,0.72-0.71,1.08-1.07c0.15-0.15,0.3-0.3,0.45-0.46c0.35-0.35,0.69-0.7,1.04-1.04c0.19-0.13,0.35-0.29,0.48-0.48
	c0.35-0.35,0.69-0.7,1.04-1.04c0.19-0.13,0.35-0.29,0.48-0.48c0.38-0.38,0.76-0.76,1.14-1.15c0.16-0.1,0.29-0.22,0.38-0.38
	c0.38-0.38,0.76-0.76,1.15-1.14c0.3-0.35,0.56-0.35,0.83,0.05c0.09,0.13,0.24,0.22,0.36,0.33c0.17,0.25,0.39,0.44,0.62,0.62
	c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62
	c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62
	c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62
	c0.27,0.33,0.57,0.63,0.91,0.91c0.21,0.21,0.41,0.41,0.62,0.62c0.23,0.24,0.43,0.51,0.76,0.63c0.52,0.41,1.11,0.6,1.76,0.6
	c0.27,0.05,0.54,0.03,0.82-0.02c0.91-0.16,1.69-0.54,2.24-1.3c0.43-0.42,0.68-0.94,0.79-1.53c0.09-0.37,0.17-0.74,0.09-1.13
	c-0.01-0.1-0.02-0.19-0.15-0.17c0.12-0.06,0.12-0.15,0.07-0.25c-0.08-0.58-0.29-1.09-0.7-1.51l-0.01-0.03
	c-0.44-0.57-0.97-1.05-1.49-1.53c-0.51-0.51-1.02-1.02-1.53-1.53c-0.53-0.53-1.07-1.07-1.6-1.6c-0.51-0.51-1.02-1.02-1.53-1.53
	c-0.48-0.48-0.97-0.97-1.45-1.45c-0.51-0.51-1.02-1.02-1.53-1.53c-0.04-0.05-0.08-0.11-0.12-0.15c-0.56-0.58-0.56-0.58,0.15-0.89
	c0.28-0.01,0.52-0.11,0.76-0.24c0.29-0.05,0.56-0.14,0.82-0.27c0.39-0.1,0.75-0.25,1.11-0.44c1.2-0.45,2.35-1.01,3.43-1.69
	c0.37-0.17,0.73-0.37,1.04-0.64c0.24-0.08,0.44-0.23,0.63-0.39l-0.01,0.01c0.06-0.05,0.12-0.09,0.18-0.14
	c1.06-0.61,1.73-1.49,1.85-2.73c0.2-0.72,0.02-1.39-0.29-2.03c-0.07-0.28-0.2-0.53-0.44-0.72c-0.11-0.24-0.31-0.38-0.5-0.54
	c-0.19-0.25-0.46-0.38-0.74-0.5c-0.59-0.35-1.23-0.47-1.91-0.39c-0.28-0.02-0.55,0.03-0.81,0.14c-0.75,0.17-1.32,0.65-1.92,1.09
	c-0.13,0.03-0.24,0.08-0.31,0.2c-1.02,0.51-2.03,1.03-3.05,1.54c-0.5,0.18-1.01,0.36-1.51,0.55c-1.01,0.31-2.03,0.56-3.06,0.78
	c-0.51,0.07-1.02,0.14-1.53,0.21c-1.59,0.15-3.18,0.14-4.78,0.01c-0.41-0.05-0.8-0.16-1.22-0.14c-1.09-0.26-2.17-0.53-3.26-0.79
	c-0.48-0.17-0.95-0.34-1.43-0.52c-0.98-0.48-1.96-0.95-2.94-1.43c-0.24-0.22-0.52-0.37-0.81-0.5C26.42,42.51,25.85,42.1,25.14,41.95
	z" />
          </svg>
          <svg v-else-if="contact.icon === 'vk'" class="fill" viewBox="0 0 78.03 78.03" x="0px" xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" y="0px">
<path d="M0.07,39c0-4.76-0.01-9.53,0.01-14.29c0.01-2.34,0.15-4.68,0.43-7c0.29-2.43,0.74-4.83,1.61-7.13
	C2.86,8.6,3.89,6.81,5.39,5.32c1.35-1.35,2.94-2.32,4.69-3.05c1.72-0.71,3.51-1.16,5.34-1.48c2.51-0.44,5.04-0.63,7.57-0.72
	C24.42,0.01,25.83,0,27.25,0c8.48,0,16.96,0,25.44,0.01c2.1,0,4.2,0.11,6.3,0.3c2.29,0.21,4.57,0.55,6.79,1.19
	c1.41,0.41,2.77,0.94,4.05,1.66c2.64,1.49,4.5,3.66,5.72,6.42c0.79,1.79,1.29,3.67,1.64,5.59c0.53,2.96,0.72,5.96,0.8,8.96
	c0.04,1.68,0.04,3.36,0.04,5.04c0,6.99,0,13.97,0,20.96c0,1.61-0.02,3.22-0.07,4.82c-0.09,2.67-0.29,5.33-0.78,7.97
	c-0.39,2.14-0.97,4.23-1.94,6.2c-1.47,2.98-3.76,5.11-6.79,6.45c-1.67,0.74-3.42,1.22-5.21,1.57c-2.01,0.38-4.04,0.6-6.08,0.73
	c-2.04,0.13-4.08,0.18-6.13,0.18c-8.17,0-16.35,0-24.52,0c-2,0-3.99-0.06-5.98-0.2c-2.55-0.18-5.07-0.48-7.55-1.12
	c-1.59-0.41-3.13-0.97-4.58-1.76c-2.69-1.47-4.6-3.65-5.85-6.43c-0.79-1.76-1.28-3.6-1.63-5.48c-0.4-2.14-0.6-4.3-0.72-6.48
	c-0.11-1.9-0.14-3.8-0.14-5.71c0-3.95,0-7.9,0-11.85C0.06,39,0.07,39,0.07,39z M43.51,32.58c0-1.88,0-3.76,0-5.64
	c0-0.44-0.03-0.87-0.18-1.29c-0.17-0.47-0.5-0.73-0.98-0.82c-0.21-0.04-0.42-0.06-0.63-0.06c-2.86,0-5.72,0-8.58,0
	c-0.07,0-0.14,0-0.21,0c-0.57,0.04-1.04,0.26-1.29,0.79c-0.24,0.51-0.11,0.99,0.21,1.43c0.17,0.23,0.34,0.45,0.52,0.67
	c0.94,1.14,1.37,2.48,1.38,3.93c0.02,2.97,0.01,5.93,0.01,8.9c0,0.37-0.04,0.73-0.16,1.08c-0.11,0.35-0.34,0.55-0.71,0.6
	c-0.21,0.03-0.4-0.02-0.59-0.09c-0.27-0.1-0.5-0.26-0.72-0.44c-0.55-0.44-1.02-0.95-1.45-1.5c-1.23-1.52-2.24-3.18-3.18-4.9
	c-1.47-2.69-2.68-5.49-3.68-8.39c-0.14-0.41-0.31-0.81-0.55-1.18c-0.31-0.47-0.74-0.76-1.29-0.85c-0.27-0.04-0.54-0.07-0.81-0.07
	c-1.74,0-3.47,0-5.21,0c-0.31,0-0.61,0.01-0.92,0.06c-0.98,0.14-1.41,0.7-1.34,1.73c0.04,0.62,0.19,1.23,0.36,1.84
	c0.6,2.22,1.45,4.34,2.43,6.41c1.8,3.83,4,7.41,6.47,10.84c1.43,1.99,3.07,3.77,4.98,5.31c2.8,2.26,5.92,3.86,9.5,4.47
	c1.52,0.26,3.05,0.29,4.58,0.09c0.4-0.05,0.79-0.15,1.15-0.33c0.51-0.25,0.79-0.65,0.85-1.22c0.03-0.28,0.05-0.56,0.05-0.85
	c0-1.67,0-3.33,0-5c0-0.31,0.02-0.61,0.09-0.91c0.11-0.43,0.35-0.71,0.81-0.78c0.34-0.06,0.68-0.07,1.02-0.01
	c0.55,0.1,1.05,0.34,1.53,0.63c1.11,0.67,2.08,1.51,3.03,2.38c1.28,1.18,2.44,2.47,3.6,3.76c0.47,0.53,0.95,1.05,1.48,1.53
	c0.65,0.59,1.39,0.93,2.27,0.93c1.87,0,3.73,0.01,5.6,0c0.46,0,0.92-0.04,1.35-0.25c0.51-0.26,0.78-0.67,0.8-1.24
	c0.02-0.45-0.08-0.87-0.25-1.27c-0.28-0.67-0.65-1.29-1.07-1.87c-1.62-2.28-3.45-4.38-5.42-6.36c-0.57-0.57-1.16-1.12-1.7-1.71
	c-0.28-0.31-0.53-0.63-0.74-0.99c-0.25-0.45-0.26-0.9-0.03-1.35c0.07-0.14,0.14-0.28,0.22-0.4c0.28-0.42,0.57-0.84,0.85-1.26
	c1.79-2.61,3.54-5.25,5.1-8c0.61-1.08,1.2-2.17,1.63-3.34c0.17-0.48,0.34-0.96,0.34-1.48c0.01-0.63-0.28-1.03-0.87-1.22
	c-0.34-0.11-0.69-0.14-1.05-0.14c-1.83,0-3.66,0-5.49,0c-0.28,0-0.57,0.03-0.84,0.09c-0.87,0.2-1.41,0.77-1.74,1.56
	c-0.42,0.99-0.87,1.96-1.36,2.92c-1.34,2.65-2.83,5.21-4.67,7.55c-0.6,0.76-1.25,1.48-1.99,2.11c-0.2,0.17-0.4,0.33-0.63,0.46
	c-0.22,0.13-0.46,0.24-0.72,0.28c-0.42,0.06-0.67-0.04-0.89-0.41c-0.2-0.35-0.26-0.73-0.26-1.13
	C43.51,36.34,43.51,34.46,43.51,32.58z" />
</svg>
          <svg v-else-if="contact.icon === 'whatsapp'" class="fill" viewBox="0 0 78.03 78.75" x="0px" xml:space="preserve"
               xmlns="http://www.w3.org/2000/svg" y="0px">
	<path d="M0,78.7c0.35-1.03,0.67-1.99,1-2.96c1.94-5.77,3.88-11.55,5.83-17.32c0.06-0.19,0.05-0.33-0.05-0.51
		C4.54,54.16,3,50.15,2.15,45.88c-0.58-2.92-0.82-5.88-0.71-8.86c0.28-7.48,2.51-14.34,6.8-20.47C13.72,8.73,21.12,3.62,30.37,1.21
		c1.79-0.47,3.62-0.77,5.46-0.95c1.91-0.2,3.82-0.25,5.74-0.15c7.7,0.4,14.7,2.85,20.89,7.44c6.83,5.07,11.49,11.71,13.97,19.85
		c0.65,2.15,1.09,4.34,1.35,6.57c0.28,2.38,0.33,4.76,0.15,7.14c-0.65,8.53-3.77,16.06-9.41,22.5c-5.16,5.9-11.57,9.83-19.15,11.84
		c-1.68,0.45-3.39,0.75-5.12,0.96c-2.18,0.26-4.37,0.33-6.56,0.22c-5.74-0.31-11.15-1.82-16.22-4.57c-0.19-0.1-0.35-0.13-0.57-0.06
		c-6.89,2.21-13.79,4.42-20.69,6.63C0.17,78.65,0.12,78.66,0,78.7z M9.93,68.9c0.14-0.04,0.23-0.07,0.31-0.1
		c3.9-1.25,7.8-2.5,11.7-3.75c0.17-0.06,0.3-0.04,0.45,0.06c0.63,0.42,1.28,0.81,1.94,1.17c4.08,2.25,8.45,3.54,13.09,3.89
		c1.67,0.12,3.33,0.11,5-0.04c1.71-0.14,3.39-0.41,5.05-0.84c7.14-1.85,13-5.68,17.51-11.51c3.77-4.87,5.92-10.4,6.5-16.54
		c0.17-1.8,0.17-3.6,0.03-5.4c-0.16-2.01-0.49-3.98-1.03-5.92c-1.85-6.6-5.43-12.07-10.75-16.38c-5.18-4.2-11.13-6.51-17.78-7.01
		c-1.84-0.14-3.68-0.1-5.52,0.09c-1.82,0.19-3.6,0.52-5.36,1.02c-8.48,2.41-15.54,8.16-19.63,15.97c-1.91,3.65-3.06,7.53-3.46,11.63
		c-0.17,1.69-0.19,3.39-0.08,5.09c0.11,1.79,0.37,3.56,0.78,5.31c0.96,4.06,2.66,7.79,5.08,11.18c0.11,0.16,0.13,0.29,0.07,0.47
		c-0.98,2.9-1.96,5.81-2.93,8.72C10.57,66.98,10.26,67.92,9.93,68.9z" />
            <path d="M20.58,29.29c0.04-3.24,1.07-5.91,3.28-8.09c0.18-0.17,0.35-0.35,0.52-0.52c0.85-0.81,1.85-1.17,3.03-1.05
		c0.61,0.07,1.22,0.14,1.84,0.12c0.49-0.01,0.87,0.21,1.16,0.59c0.25,0.33,0.42,0.7,0.57,1.08c0.81,2.19,1.63,4.39,2.44,6.58
		c0.12,0.32,0.24,0.63,0.37,0.94c0.25,0.6,0.2,1.15-0.09,1.74c-0.46,0.92-1.14,1.64-1.81,2.39c-0.28,0.31-0.58,0.61-0.88,0.9
		c-0.65,0.63-0.72,1.1-0.29,1.89c1.29,2.36,2.82,4.54,4.7,6.46c2.08,2.12,4.47,3.83,7.16,5.11c0.37,0.18,0.73,0.36,1.1,0.54
		c0.12,0.06,0.24,0.11,0.36,0.16c0.6,0.23,1.13,0.14,1.57-0.33c1.14-1.22,2.27-2.44,3.29-3.77c0.09-0.11,0.18-0.23,0.28-0.33
		c0.33-0.33,0.71-0.46,1.18-0.35c0.4,0.09,0.78,0.25,1.15,0.43c1.54,0.75,3.05,1.57,4.57,2.38c0.86,0.46,1.71,0.92,2.56,1.38
		c0.23,0.12,0.45,0.25,0.64,0.42c0.19,0.16,0.3,0.35,0.33,0.6c0.08,0.6,0,1.19-0.1,1.77c-0.14,0.83-0.38,1.63-0.67,2.42
		c-0.23,0.6-0.6,1.12-1.05,1.57c-1.06,1.06-2.31,1.81-3.68,2.39c-0.71,0.3-1.44,0.54-2.21,0.58c-0.43,0.03-0.84,0.15-1.27,0.21
		c-0.74,0.1-1.47,0-2.19-0.16c-1.52-0.33-2.98-0.87-4.43-1.44c-1.7-0.67-3.4-1.34-5.03-2.18c-2.48-1.27-4.69-2.91-6.73-4.79
		c-3.05-2.82-5.6-6.04-7.8-9.56c-0.52-0.84-1.09-1.66-1.57-2.53c-0.86-1.59-1.56-3.24-1.96-5.01C20.7,30.97,20.59,30.05,20.58,29.29
		z" />
</svg>
        </div>
        <div v-if="editItem" class="admin-input">
          <textarea v-model="contact.mainInfo" rows="1"></textarea>
        </div>
        <div v-else class="contact__item-mainInfo">{{ contact.mainInfo }}</div>
      </div>
      <div v-if="editItem" class="admin-input">
        <textarea v-model="contact.additionalInfoEng" rows="1" v-if="isEnglish"></textarea>
        <textarea v-model="contact.additionalInfo" rows="1" v-else></textarea>
      </div>
      <div v-else class="contact__item-additionalInfo">
        <span v-if="isEnglish">{{ contact.additionalInfoEng }}</span>
        <span v-else>{{ contact.additionalInfo }}</span>
      </div>
    </a>
    <div v-if="contact.workHours && !editItem" class="contact__item-workHours">
      <span v-html="isEnglish ? contact.workHoursEng : contact.workHours"></span>
    </div>
    <div v-if="contact.workHours && editItem" class="admin-input">
      <textarea v-model="contact.workHoursEng" rows="2" v-if="isEnglish"></textarea>
      <textarea v-model="contact.workHours" rows="2" v-else></textarea>
    </div>

  </div>
</template>

<script>
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";

export default {
  name: "ContactsPageListItem",
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    contactType: {
      type: String
    },
    parentId: {
      type: Number
    }
  },
  components: {
    AdminWidgetEditDelete
  },
  data() {
    return {
      contactIcons: ["vk", "whatsapp", "telegram", "odnoklassniki"],
      editItem: false,
      widgetOptions: {
        mutationName: "deleteContact",
        swapItems: {
          mutationNames: [],
          type: ""
        },
        parentId: this.parentId
      }
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getContactLink() {
      if (this.contactType === "tel") {
        return `tel:`;
      } else if (this.contactType === "email") {
        return `mailto:`;
      } else if (this.contactType === "social") {
        return `https://`;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.contact {

  &__item {
    margin-bottom: 50px;
    padding-left: 35px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 15px;
      padding-left: 0;
    }

    &:last-child {
      margin-bottom: 0
    }

    &-link {
      color: $black-color;
    }

    &-link:hover {
      color: $secondary-color;
      cursor: pointer;

      .fill {
        fill: $secondary-color
      }

      .stroke {
        stroke: $secondary-color
      }
    }

    &-flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-icon {
      height: 22px;
      margin-right: 6px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        height: 15px;
        margin-top: -8px;
        margin-right: 3px;
      }

      > svg {
        height: 23px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          height: 11px;
        }
      }
    }

    &-mainInfo {
      font-weight: 700;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: 11px;
      }
    }

    &-additionalInfo {
      margin-bottom: 14px;
      padding-left: 29px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-bottom: 0 ;
        padding-left: 14px;
        font-size: $fontSizeMobileM;
      }
    }

    &-workHours {

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-bottom: 10px;
        padding-left: 14px;
        font-size: $fontSizeMobileM;
      }
    }
  }
}

.admin {
  &-input {
    max-width: 100%;
    width: 450px;
    margin-bottom: 5px;
    padding-top: 10px;

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}
</style>