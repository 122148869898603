<template>
  <div class="contact">
    <div class="contact__title">
      <span v-if="isEnglish">{{ contact.titleEng }}</span>
      <span v-else>{{ contact.title }}</span>
    </div>
    <div class="contact__items">
      <contacts-page-list-item v-for="(item, index) in contact.items" :key="index"
                               :contact="item" :contact-type="contact.type"
                               :parent-id="contact.id"></contacts-page-list-item>
    </div>
  </div>
</template>

<script>


import ContactsPageListItem from "@/components/pages/ContactsPageListItem";

export default {
  name: "ContactsPageList",
  components: { ContactsPageListItem },
  props: {
    contact: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptions: {
        mutationName: "deleteAct",
        swapItems: false
      }
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.contact {
  margin-top: 30px;
  flex-shrink: 0;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 24px;
    color: $grey-color-dark;
    font-size: $fontSizeL;
    font-weight: 700;
    line-height: 25px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 8px;
      font-size: $fontSizeMobileL;
      line-height: 16px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: $mobile-version) and (orientation: portrait) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

</style>