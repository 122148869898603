<template>
  <div class="list">
    <div v-if="editItem && editSections" class="admin-input">
      <textarea v-model="actBlock.section.titleEnglish" v-if="isEnglish" rows="1"></textarea>
      <textarea v-model="actBlock.section.title" v-else rows="1"></textarea>
    </div>
    <div v-else class="list__title">
      <span v-if="isEnglish">{{ actBlock.section.titleEnglish }}</span>
      <span v-else> {{ actBlock.section.title }} </span>
    </div>
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus && editSections"
      :item-edited="editItem"
      :item-id="actBlock.section.id"
      :options="widgetOptionsDelete"
      @toggleEdit="editSection"
    ></admin-widget-edit-delete>

    <admin-widget-add-new
      v-if="checkContentManagerStatus && editSubsections"
      :options="widgetOptionsAdd"
      @addSubsection="addActSubsection($event)"
    ></admin-widget-add-new>

    <div>
      <acts-page-section-list
        v-for="(child, index) in actBlock.children"
        :key="index"
        :act-block="child"
        :acts-with-no-search="child.docs"
        :editActs="editActs"
        :editSubsections="editSubsections"
        :searchInput="searchInput"
        :section-id="actBlock.section.id" />
    </div>
  </div>

</template>

<script>
import ActsPageSectionList from "@/components/pages/ActsPageSectionList";
import AdminWidgetEditDelete from "../admin/AdminWidgetEditDelete";
import AdminWidgetAddNew from "../admin/AdminWidgetAddNew";
import request from "@/services/request";

export default {
  name: "ActsPageSection",
  components: {
    AdminWidgetAddNew,
    AdminWidgetEditDelete,
    ActsPageSectionList
  },
  props: {
    searchInput: {
      type: String,
      default: ""
    },
    actBlock: {
      type: Object,
      default: () => []
    },
    editSections: {
      type: Boolean,
      default: false
    },
    editSubsections: {
      type: Boolean,
      default: false
    },
    editActs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptionsAdd: {
        title: "Добавить подраздел",
        titleEnglish: "Add act",
        eventName: "addSubsection",
        inputs: [
          {
            label: "Название подраздела",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Название подраздела (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "titleEnglish"
          }
        ]
      },
      widgetOptionsDelete: {
        mutationName: "",
        swapItems: {
          mutationNames: [],
          type: ""
        }
      }
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  },
  methods: {
    async addActSubsection(event) {
      event.newObj.id = null;
      event.newObj.path = null;
      event.newObj.parent = this.actBlock.section.title;
      event.newObj.parentId = this.actBlock.section.id;
      const res = await request({
        endpoint: `/api/sections`,
        body: event.newObj,
        method: "POST"
      });
      const objToPush = {
        section: res.data,
        docs: [],
        children: []
      };
      this.actBlock.children.push(objToPush);
      if (res.status !== 200) {
        alert("Ошибка добавления");
      }
      //console.log("ADD_SECTION", res.data);
    },
    async editSection() {
      if (!this.editItem) {
        this.editItem = true;
      } else {
        const res = await request({
          endpoint: `/api/sections`,
          body: this.actBlock.section,
          method: "PUT"
        });
        this.editItem = false;
        if (res.status !== 200) {
          alert("Ошибка редактирования");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.list {
  margin-bottom: 60px;

  @media (max-width: $mobile-version) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 6px;
    color: $primary-color;
    font-size: 18px;
    font-weight: 800;
    line-height: 23px;

    @media (max-width: $mobile-version) {
      margin-bottom: 2px;
      font-size: $fontSizeMobileXS;
      line-height: 10px;
    }
  }
}

.admin {
  &-input {
    max-width: 100%;
    width: 600px;

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}
</style>