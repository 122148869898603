<template>
  <div class="act">
    <div v-if="editItem && editActs">
      <div class="admin-input">
        <textarea v-model="act.titleEnglish" rows="4" v-if="isEnglish"></textarea>
        <textarea v-model="act.title" rows="4" v-else></textarea>
      </div>
      <div class="admin-input">
        <textarea v-model="act.descriptionEnglish" rows="4" v-if="isEnglish"></textarea>
        <textarea v-model="act.description" rows="4" v-else></textarea>
      </div>
    </div>
    <div v-else class="act__main-info">
      <div class="act__main-info-icon">
        <img alt="Иконка документа" src="@/assets/images/icon-act.svg" />
      </div>
      <div>
        <router-link :to="getActLink" class="act__link">
          <div class="act__main-info-title" v-html="act.titleEnglish" v-if="isEnglish"></div>
          <div class="act__main-info-title" v-html="act.title" v-else></div>
          <div class="act__main-info-description" v-html="act.descriptionEnglish" v-if="isEnglish"></div>
          <div class="act__main-info-description" v-html="act.description" v-else></div>
        </router-link>
      </div>
    </div>
    <div class="act__info-block">
      <div
        :style="{ display: !showInfoBtn ? 'block' : 'none' }"
        class="act__info-block-image"
        @click="showModal = true"
        @mouseleave="showInfoBtn = false"
        @mouseover="showInfoBtn = true"
      >
        <img src="@/assets/images/icon-info.svg" />
      </div>
      <div
        :style="{ display: showInfoBtn ? 'block' : 'none' }"
        class="act__info-block-text"
        @click="showModal = true"
        @mouseleave="showInfoBtn = false"
        @mouseover="showInfoBtn = true"
      >
        Справка
      </div>
    </div>
    <div
      class="act__info-block-image-mobile"
      @click="showModal = true"
    >
      <img src="@/assets/images/icon-info.svg" />
    </div>
    <div
      :style="{ display: showModal ? 'flex' : 'none' }"
      class="act__info-block-modal"
      @click.self="showModal = false"
    >
      <div class="act__info-block-modal-box">
        <div class="info-block-modal__title">Справка к документу</div>

        <div class="info-block-modal__subtitle">Источник публикации</div>
        <div v-if="editItem && editActs" class="widget-input-editor">
          <editor
            v-model="act.modalSource"
            :apiKey="getEditorKey"
            :init="getEditorOptions"
          />
        </div>
        <div
          v-else
          class="info-block-modal__text info-block-modal__text-source"
          v-html="act.modalSource"
        >
        </div>

        <div class="info-block-modal__subtitle">Примечание к документу</div>
        <div v-if="editItem && editActs" class="widget-input-editor">
          <editor
            v-model="act.modalComment"
            :apiKey="getEditorKey"
            :init="getEditorOptions"
          />
        </div>
        <div
          v-else
          class="info-block-modal__text info-block-modal__text-comment"
          v-html="act.modalComment"
        >
        </div>

        <div v-if="getCurrentPath === '/mezhdunarodnye'">
          <div class="info-block-modal__subtitle">Договаривающиеся стороны</div>
          <div v-if="editItem && editActs" class="widget-input-editor">
            <editor
              v-model="act.modalParties"
              :apiKey="getEditorKey"
              :init="getEditorOptions"
            />
          </div>
          <div
            v-else
            class="info-block-modal__text info-block-modal__text-comment"
            v-html="act.modalParties"
          >
          </div>
        </div>

        <div class="info-block-modal__subtitle">Название документа</div>
        <div v-if="editItem && editActs" class="widget-input-editor">
          <editor
            v-model="act.modalTitle"
            :apiKey="getEditorKey"
            :init="getEditorOptions"
          />
        </div>
        <div
          v-else
          class="info-block-modal__text info-block-modal__text-title"
          v-html="act.modalTitle"
        >
        </div>
        <img
          class="info-block-modal__icon-close"
          src="@/assets/images/icon-close.svg"
          @click="showModal = false"
        />
      </div>
    </div>
    <admin-widget-edit-delete-new
      v-if="checkContentManagerStatus && editActs"
      :item-edited="editItem"
      :options="widgetOptions"
      @deleteAct="deleteAct"
      @toggleEdit="editAct"
    />
  </div>
</template>

<script>
import AdminWidgetEditDeleteNew from "@/components/admin/AdminWidgetEditDeleteNew";
import Editor from "@tinymce/tinymce-vue";
import request from "@/services/request";

export default {
  name: "ActsPageSectionListItem",
  components: {
    AdminWidgetEditDeleteNew,
    Editor
  },
  props: {
    act: {
      type: Object,
      default: () => {
      }
    },
    parentId: {
      type: Number
    },
    sectionId: {
      type: Number
    },
    editActs: {
      type: Boolean,
      default: false
    },
    searchInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptions: {
        eventName: "deleteAct",
        swapItems: {
          mutationNames: [],
          type: ""
        }
      },
      showInfoBtn: false,
      showModal: false
    };
  },
  computed: {
    getActLink() {
      return this.getCurrentPath + "/" + this.act.id;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    getCurrentPath() {
      return this.$route.path;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "250";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    },
    isMobile() {
      return window.innerWidth <= 768;
    }
  },
  methods: {
    async editAct() {
      if (!this.editItem) {
        this.editItem = true;
      } else {
        let objToSend = { ...this.act };
        objToSend.text = null;
        objToSend.section = {};
        objToSend.section.id = this.parentId;
        const res = await request({
          endpoint: `/api/acts`,
          body: objToSend,
          method: "PUT"
        });
        this.editItem = false;
        if (res.status !== 200) {
          alert("Ошибка редактирования");
        }
      }
    },
    async deleteAct() {
      const res = await request({
        endpoint: `/api/acts/${this.act.id}`,
        method: "DELETE"
      });
      this.$emit("deleteActItem", { actId: this.act.id });
      if (res.status !== 200) {
        alert("Ошибка удаления");
      }
      //console.log("DELETE_ACT", res.data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.act {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    margin-bottom: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__main-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 60px;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      padding-right: 10px;
    }

    &-title {
      font-weight: 700;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: 7px;
        line-height: 9px;
      }
    }

    &-description {

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: 7px;
        line-height: 9px;
      }
    }

    &-icon {
      width: 55px;
      min-height: 40px;
      position: relative;
      flex-shrink: 0;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        min-height: 22px;
        width: 24px;
      }
    }

    &-icon > img {
      width: 34px;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.2s;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        width: 18px;
      }
    }

    &:hover &-icon > img {
      width: 44px;
      top: -7px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        width: 18px;
        top: 0;
      }
    }

    &:hover &-title,
    &:hover &-description,
    &:hover &-search-text {
      color: $primary-color;
    }
  }

  &__link {
    color: $black-color;
  }

  &__title {
    font-weight: 500;
  }

  &__info-block {
    flex-shrink: 0;
    width: 90px;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 11px;
      display: none;
    }

    &-image {
      padding-right: 90px;

      &-mobile {
        width: 11px;
        display: none;
        padding-right: 0;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          display: block;
        }

        > img {
          width: 11px;
        }
      }
    }

    &-text {
      flex-shrink: 0;
      padding: 2px 8px 1px 7px;
      color: $primary-color;
      border: 3px solid $primary-color;
      border-radius: 20px;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        display: none;
        padding: 1px;
        border: none;
        font-size: $fontSizeMobileXS;
      }
    }

    &-modal {
      position: fixed;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: default;
      z-index: 200;

      &-box {
        position: absolute;
        width: 730px;
        max-height: 90vh;
        margin: auto;
        padding: 30px;
        background-color: $white-color;
        border-radius: $borderRadiusMobile;
        overflow-y: auto;
        overscroll-behavior-y: none;
        z-index: 200;

        @media (max-width: $mobile-version) and (orientation: portrait) {
          width: 275px;
          padding: 12px 10px;
        }

        @media (max-width: $mobile-version) and (orientation: landscape) {
          width: 60%;
          border-radius: $borderRadiusMobile;
        }
      }

      .info-block-modal {
        &__title {
          margin-bottom: 16px;
          font-weight: 600;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            margin-bottom: 4px;
            font-size: $fontSizeMobileXS;
          }
        }

        &__subtitle {
          font-size: $fontSizeS;
          font-weight: 600;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            font-size: 7px;
          }
        }

        &__text {
          margin-bottom: 20px;
          font-size: $fontSizeS;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            font-size: 7px;
          }

          &-title {
            margin-bottom: 0;
          }
        }

        &__icon-close {
          position: absolute;
          top: 30px;
          right: 30px;
          cursor: pointer;

          @media (max-width: $mobile-version) and (orientation: portrait) {
            width: 12px;
            top: 12px;
            right: 15px;
          }
        }
      }
    }
  }
}

.admin {
  &-input {
    max-width: 100%;
    width: 900px;
    margin-bottom: 5px;

    > textarea {
      width: 100%;
      padding: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

.widget-input-editor {
  margin-bottom: 10px;
}

</style>