<template>
  <div>
    <warning-phone-rotate />
    <page-header :show-page-title="false" />
    <main-loader v-if="!eventItemData" />
    <div v-else class="event-item">
      <div class="event-item__title">{{ pageTitle }}</div>
      <div class="event-item__params">
        <div class="event-item__time">{{ eventTime }} (мск)</div>
        <div class="event-item__date">{{ eventDate }}</div>

      </div>
      <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" >
        <template #activeBtn>
          <button
              class="admin__button"
              type="button"
              @click="sendToPast">
            {{eventIsActive ? 'Перевести в прошедшие' : 'Перевести в запланированные'}}
          </button>
        </template>
      </admin-buttons-block>
      <div class="event-item__material">
        <admin-widget-edit-delete
          v-if="checkContentManagerStatus"
          :item-edited="editItem"
          :item-id="eventItemData.id"
          :options="widgetOptions"
          @toggleEdit="editItem = !editItem"
        />
        <div v-if="editItem" class="admin-input" >
          <editor
            v-model="eventItemData.material"
            :apiKey="getEditorKey"
            :init="getEditorOptions"
            style="margin: 0 auto;"
          />
        </div>
        <div v-else v-html="eventItemData.material"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import AdminButtonsBlock from "../admin/AdminButtonsBlock";
import moment from "moment";
import MainLoader from "../MainLoader";
import WarningPhoneRotate from "../WarningPhoneRotate";
import Editor from "@tinymce/tinymce-vue";
import AdminWidgetEditDelete from "../admin/AdminWidgetEditDelete";
import {mapActions} from "vuex";


export default {
  name: "EventListItem",
  components: {
    WarningPhoneRotate,
    MainLoader,
    AdminButtonsBlock,
    PageHeader,
    Editor,
    AdminWidgetEditDelete
  },
  data() {
    return {
      editItem: false,
      widgetOptions: {
        mutationName: "",
        swapItems: ""
      }
    };
  },
  mounted() {
    this.$store.dispatch("downloadEventList");
  },
  computed: {
    adminButtonsOptions() {
      return {
        actionNameSave: "sendEventList",
        actionNameCancel: "downloadEventList",
        item: this.eventItemData
      }
    },
    eventIsActive(){
      return this.eventItemData?.active
    },
    statusEvent(){
      return this.eventItemData?.active ?? true
    },
    eventItemData() {
      const eventId = this.$route.path.split("/")[2];
      return this.$store.getters.getEventList[+eventId];
    },
    pageTitle() {
      const eventTextWithoutTags = this.eventItemData.text.replace(/<\/?[a-zA-Z]+>/gi, "").replace(/&nbsp;/gi, "");
      return this.eventItemData.title + " " + eventTextWithoutTags;
    },
    eventDate() {
      return moment(this.eventItemData.datetime).locale("ru").format("L");
    },
    eventTime() {
      return moment(this.eventItemData.datetime).utcOffset(3).locale("ru").format("LT");
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "800";
      options.width = "1140";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    },
  },
  methods: {
    ...mapActions({changeStatusEvent:'changeStatusEvent'}),
    sendToPast(){
      if(this.eventItemData)
        this.changeStatusEvent(this.eventItemData);
    }
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.event-item {
  color: $black-color;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }

  &__section {
    margin-bottom: 38px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 25px;
      font-size: $fontSizeMobileS;
    }
  }

  &__title {
    margin-bottom: 23px;
    color: $primary-color;
    font-size: $fontSizeXXL;
    font-weight: 800;
    line-height: 45px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: $fontSizeMobileXL;
      line-height: 18px;
    }
  }

  &__params {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 68px;
    color: $grey-color;
    font-size: 30px;
    font-weight: 700;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 35px;
      font-size: $fontSizeMobileXL;
      line-height: 18px;
    }
  }
}

.admin {
  &-input {
    width: 100%;

    > * {
      margin-bottom: 5px;
    }

    > select {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}


</style>