<template>
  <div class="container views">
    <keep-alive include="EventList" :max="3">
<!--    Если в списке навигации есть поле с названием компонента, то выбирает его и показывает соответствующую страницу,
но есть отдельные страницы, которые просто показываются по пути и их нет в списке навигации-->
    <contacts-page v-if="getPageType === 'Страница контактов'" />
    <card-list v-else-if="getPageType === 'Страница с карточками'" />
    <toggle-number-block-list
      v-else-if="getPageType === 'Список раскрывающихся блоков с номерами'"
    />
    <event-list v-else-if="getPageType === 'Страница мероприятий'" />
    <event-list-item-page v-else-if="getPageType === 'Страница мероприятия'"/>
    <toggle-block-list
      v-else-if="getPageType === 'Список раскрывающихся блоков'"
    />
    <acts-page v-else-if="getPageType === 'Список актов'" />
    <act-text-page v-else-if="getPageType === 'Текст акта'" />
    <rossotrudnichestvo-page v-else-if="getPageType === 'Россотрудничество'" />
    <sber-credit-page v-else-if="getPageType === 'Кредит'" />
    <not-found-page v-else />
    </keep-alive>
  </div>
</template>

<script>
import ContactsPage from "@/components/pages/ContactsPage";
import CardList from "@/components/pages/CardList";
import ActsPage from "@/components/pages/ActsPage";
import EventList from "@/components/pages/EventList";
import ToggleBlockList from "@/components/pages/ToggleBlockList";
import ToggleNumberBlockList from "@/components/pages/ToggleNumberBlockList";
import NotFoundPage from "@/components/pages/NotFoundPage";
import ActTextPage from "@/components/pages/ActTextPage";
import RossotrudnichestvoPage from "@/components/pages/RossotrudnichestvoPage";
import SberCreditPage from "@/components/pages/SberCreditPage";
import EventListItemPage from "./pages/EventListItemPage";

export default {
  name: "AdminGenerateView",
  components: {
    SberCreditPage,
    RossotrudnichestvoPage,
    ActTextPage,
    NotFoundPage,
    ToggleNumberBlockList,
    ToggleBlockList,
    EventList,
    ActsPage,
    CardList,
    ContactsPage,
    EventListItemPage
  },
  data() {
    return {};
  },
  computed: {
    getPageType() {
      window.scrollTo(0, 0);
      const paths = this.$route.path.split("/").slice(1);
      if (paths.length === 1) {
        const currentPath = "/" + paths.join("");
        if (this.$route.path === "/sber_credit") {
          return "Кредит";
        } else {
          let loadedComponentType;
          this.$store.getters.getNavSections.map((nav) => {
            if (nav.link === currentPath) {
              loadedComponentType = nav.componentType;
            } else {
              nav.sections.map((section) => {
                if (section.link === currentPath) {
                  loadedComponentType = section.componentType;
                } else {
                  section.items.map((item) => {
                    if (item.link === currentPath) {
                      loadedComponentType = item.componentType;
                    }
                  });
                }
              });
            }
          });
          return loadedComponentType;
        }
      } else {
        if (this.$route.path === "/kvota/rossotrudnichestvo") {
          return "Россотрудничество";
        } else if (paths[0] === 'events') {
          return 'Страница мероприятия'
        } else {
          return "Текст акта";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.views {
  @media (max-width: $mobile-version) and (orientation: landscape) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

</style>