<template>
  <div>
    <warning-phone-rotate />
    <div class="credit">
      <page-header />
      <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
      <admin-widget-edit-delete
        v-if="checkContentManagerStatus"
        :item-edited="editItem"
        :options="widgetOptions"
        @toggleEdit="editItem = !editItem" />
      <div v-if="editItem" class="credit-text">
        <editor
          v-model="getSberCreditInfo.text"
          :apiKey="getEditorKey"
          :init="getEditorOptions"
        />
      </div>
      <div v-else v-html="getSberCreditInfo.text"></div>
    </div>
  </div>

</template>

<script>
import PageHeader from "@/components/PageHeader";
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";
import Editor from "@tinymce/tinymce-vue";
import AdminButtonsBlock from "@/components/admin/AdminButtonsBlock";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";

export default {
  name: "SberCreditPage",
  components: {
    WarningPhoneRotate,
    AdminButtonsBlock,
    AdminWidgetEditDelete,
    Editor,
    PageHeader
  },
  data() {
    return {
      editItem: false,
      widgetOptions: {
        mutationName: "",
        swapItems: ""
      },
      adminButtonsOptions: {
        actionNameSave: "sendSberCreditInfo",
        actionNameCancel: "downloadSberCreditInfo"
      }
    };
  },
  mounted() {
    this.$store.dispatch("downloadSberCreditInfo");
  },
  computed: {
    getSberCreditInfo() {
      return this.$store.getters.getSberCreditInfo;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "800";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.credit {
  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }
}
</style>