<template>
  <div>
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus"
      :item-edited="editItem"
      :item-id="item.id"
      :options="widgetOptionsDelete"
      @toggleEdit="editItem = !editItem"
    />
    <div v-if="editItem" class="rossotr__table-row-flex admin-input">
      <textarea v-model="item.countryEng" v-if="isEnglish" rows="1"></textarea>
      <textarea v-model="item.country" v-else rows="1"></textarea>
      <editor
        v-model="item.infoEng"
        v-if="isEnglish"
        :apiKey="getEditorKey"
        :init="getEditorOptions"
      />
      <editor
        v-model="item.info"
        v-else
        :apiKey="getEditorKey"
        :init="getEditorOptions"
      />
    </div>
    <div v-else class="rossotr__table-row-flex">
      <div class="rossotr__table-cell-country" v-if="isEnglish" v-html="item.countryEng"></div>
      <div class="rossotr__table-cell-country" v-else v-html="item.country"></div>
      <div class="rossotr__table-cell-info" v-if="isEnglish" v-html="item.infoEng"></div>
      <div class="rossotr__table-cell-info" v-else v-html="item.info"></div>
    </div>
  </div>
</template>

<script>
import AdminWidgetEditDelete from "../admin/AdminWidgetEditDelete";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "RossotrudnichestvoPageItem",
  components: {
    AdminWidgetEditDelete,
    Editor
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    searchInput: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptionsDelete: {
        mutationName: "deleteRosSotrInfoItem",
        swapItems: ""
      }
    };
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "200";
      options.width = "850";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.rossotr {

  &__table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid $grey-color;
    border-bottom: none;

    &-row {
      width: 100%;

      &-flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid $grey-color;
      }
    }

    &-cell {
      &-country, &-info {
        min-height: 82px;
        padding: 15px 30px;
        font-size: $fontSizeL;
        text-align: center;

      }

      &-country {
        height: 100%;
        text-transform: uppercase;
        flex-basis: 350px;
        flex-shrink: 0;
      }

      &-info {
        flex-grow: 1;
        border-left: 1px solid $grey-color;
      }
    }
  }
}

.admin {

  &-input {
    width: 100%;

    > textarea {
      width: 300px;
      padding: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}
</style>