<!--
Тут немного другая логика, все делается через бэк, нужно будет этот компонент везде добавить, когда бэк будет полностью готов
Подлючаем в компонент через
     <admin-widget-edit-delete-new
      v-if="checkContentManagerStatus"
      :item-edited="editItem" для отображения виджета, при включенном редактировании
      :options="widgetOptions"
      @deleteAct="deleteAct"
      @toggleEdit="editAct"
    />
В пропс передаем объект
    widgetOptions: {
        eventName: "deleteAct", тут уже название события удаления, которое совпадает с названием во вставленном компоненте
        swapItems: { пока взято из старого виджета, так как не было необходимости перемещать блоки, нужно будет переделать
        и прикрепить к функциям отправки запросов на бэк, например как в методах внизу
          eventNameUp: "",
          eventNameDown: "",
          type: ""
        }
      },
    }
В родительском компоненте должно быть свойство editItem, которое тоже передается сюда-->
<template>
  <div class="widget-buttons">
    <button
      class="widget-button"
      title="Редактировать"
      type="button"
      @click="$emit('toggleEdit')"
    >
      <svg
        v-if="!itemEdited"
        height="25"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m179.2,410.4l-77.3-77.4 272.5-272.9 77.3,77.4-272.5,272.9zm-96-38.3l56.9,57-79.2,22.3 22.3-79.3zm411.8-249l-106.2-106.4c-7.7-7.7-21.2-7.7-28.9-3.55271e-15l-301.3,301.8c-2.5,2.5-4.3,5.5-5.2,8.9l-41.6,148c-2,7.1 0,14.8 5.2,20 3.9,3.9 11.7,6.7 20,5.2l147.8-41.7c3.4-0.9 6.4-2.7 8.9-5.2l301.3-301.7c8-8 8-20.9 0-28.9z"
        />
      </svg>
      <svg
        v-else
        height="25"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0,9.014L1.414,7.6L5.004,11.189L14.593,1.6L16.007,3.014L5.003,14.017L0,9.014Z"
        />
      </svg>
    </button>
    <button
      class="widget-button"
      title="Удалить"
      type="button"
      v-if="this.options.eventName"
      @click="deleteItem"
    >
      <svg
        height="25"
        viewBox="0 0 172.541 172.541"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
      >
        <path
          d="M166.797,25.078h-13.672h-29.971V0H49.388v25.078H19.417H5.744v15h14.806l10,132.463h111.443l10-132.463h14.805V25.078zM64.388,15h43.766v10.078H64.388V15z M128.083,157.541H44.46L35.592,40.078h13.796h73.766h13.796L128.083,157.541z"
        />
        <rect height="66.232" width="12" x="80.271" y="65.693" />
        <rect height="66.232" width="12" x="57.271" y="65.693" />
        <rect height="66.232" width="12" x="103.271" y="65.693" />
      </svg>
    </button>
    <button
      v-if="options.swapItems.type === 'upDown'"
      class="widget-button"
      title="Переместить выше"
      type="button"
      @click="moveItemUp"
    >
      <svg height="25" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <polygon
          points="9 3.828 2.929 9.899 1.515 8.485 10 0 10.707 .707 18.485 8.485 17.071 9.899 11 3.828 11 20 9 20 9 3.828"
        />
      </svg>
    </button>
    <button
      v-if="options.swapItems.type === 'upDown'"
      class="widget-button"
      style="transform: rotate(180deg)"
      title="Переместить ниже"
      type="button"
      @click="moveItemDown"
    >
      <svg height="25" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <polygon
          points="9 3.828 2.929 9.899 1.515 8.485 10 0 10.707 .707 18.485 8.485 17.071 9.899 11 3.828 11 20 9 20 9 3.828"
        />
      </svg>
    </button>
    <button
      v-if="options.swapItems.type === 'leftRight'"
      class="widget-button"
      style="transform: rotate(-90deg)"
      title="Переместить влево"
      type="button"
      @click="moveItemUp"
    >
      <svg width="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <polygon
          points="9 3.828 2.929 9.899 1.515 8.485 10 0 10.707 .707 18.485 8.485 17.071 9.899 11 3.828 11 20 9 20 9 3.828"
        />
      </svg>
    </button>
    <button
      v-if="options.swapItems.type === 'leftRight'"
      class="widget-button"
      style="transform: rotate(90deg)"
      title="Переместить вправо"
      type="button"
      @click="moveItemDown"
    >
      <svg height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <polygon
          points="9 3.828 2.929 9.899 1.515 8.485 10 0 10.707 .707 18.485 8.485 17.071 9.899 11 3.828 11 20 9 20 9 3.828"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "AdminWidgetEditDelete",
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    itemEdited: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteItem() {
      const accept = confirm("Вы точно хотите удалить элемент?");
      if (accept && this.options.eventName) {
        this.$emit(this.options.eventName)
      }
    },
    moveItemUp() {
      //this.$emit(this.options.swapItems.eventNameUp)
    },
    moveItemDown() {
      //this.$emit(this.options.swapItems.eventNameDown)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.widget {
  &-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 20px;
  }

  &-button {
    margin-left: 20px;
    padding: 10px 15px;
    font-size: $fontSizeL;
    background-color: #e7e7e8;
    border-radius: 10px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: $white-color;
      background-color: $primary-color;

      > svg {
        fill: $white-color;
      }
    }
  }
}
</style>