<template>
  <div>
    <warning-phone-rotate />
    <div class="rossotr">
      <page-header />
      <div class="rossotr__title">
        <span v-if="isEnglish">
          Contact information of the Rossotrudnichestvo representative offices around the world
        </span>
        <span v-else>
          Контактная информация представительств<br> Россотрудничества по всему миру
        </span>
      </div>
      <search-bar style="margin-bottom: 50px" @getSearchInput="searchInput = $event" />
      <admin-buttons-block v-if="checkContentManagerStatus" :options="adminButtonsOptions" />
      <admin-widget-add v-if="checkContentManagerStatus" :options="widgetOptionsAdd" />
      <div class="rossotr__table">
        <div class="rossotr__table-row">
          <div class="rossotr__table-row-flex">
            <div class="rossotr__table-cell-country rossotr__table-head">
              <span v-if="isEnglish">Country</span>
              <span v-else>СТРАНА</span>
            </div>
            <div class="rossotr__table-cell-info rossotr__table-head">
              <span v-if="isEnglish">Contacts of the Embassies of Russia / Rossotrudnichestvo
                representative offices</span>
              <span v-else>КОНТАКТНАЯ ИНФОРМАЦИЯ (ПО СТРАНАМ) ДЛЯ ПОЛУЧЕНИЯ ДОПОЛНИТЕЛЬНОЙ ИНФОРМАЦИИ</span>
            </div>
          </div>
        </div>
        <div v-if="!getRosSotrInfo.length" class="rossotr__table-no-data">Данных нет</div>
        <div v-else class="rossotr__table-row">
          <div v-for="(item, index) in getRosSotrInfo" :key="index">
            <rossotrudnichestvo-page-item :item="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SearchBar from "@/components/SearchBar";
import AdminWidgetAdd from "../admin/AdminWidgetAdd";
import AdminButtonsBlock from "../admin/AdminButtonsBlock";
import WarningPhoneRotate from "@/components/WarningPhoneRotate";
import RossotrudnichestvoPageItem from "./RossotrudnichestvoPageItem";

export default {
  name: "RossotrudnichestvoPage",
  components: {
    RossotrudnichestvoPageItem,
    WarningPhoneRotate,
    AdminButtonsBlock,
    AdminWidgetAdd,
    SearchBar,
    PageHeader
  },
  data() {
    return {
      editItem: false,
      searchInput: "",
      adminButtonsOptions: {
        actionNameSave: "sendRosSotrInfo",
        actionNameCancel: "downloadRosSotrInfo"
      },
      widgetOptionsAdd: {
        title: "Добавить контакт",
        mutationName: "addRosSotrInfoItem",
        inputs: [
          {
            label: "Страна",
            numberOfRows: 1,
            vModelKeyName: "country"
          },
          {
            label: "Страна (Английская версия)",
            numberOfRows: 1,
            vModelKeyName: "countryEng"
          },
          {
            label: "Информация",
            numberOfRows: 5,
            vModelKeyName: "info",
            editorEnable: true,
            editorHeight: "200"
          },
          {
            label: "Информация (Английская версия)",
            numberOfRows: 5,
            vModelKeyName: "infoEng",
            editorEnable: true,
            editorHeight: "200"
          }
        ]
      }
    };
  },
  mounted() {
    this.$store.dispatch("downloadRosSotrInfo");
  },
  computed: {
    getRosSotrInfo() {
      let rosSotrInfo = [...this.$store.getters.getRosSotrInfo];
      let filteredRosSotrInfo = [...rosSotrInfo];

      filteredRosSotrInfo.forEach(item => {
        if (item.country) item.country = item.country.replaceAll(/<\/*mark>/g, "");
        if (item.info) item.info = item.info.replaceAll(/<\/*mark>/g, "");
        //if (item.countryEng) item.countryEng = item.countryEng.replaceAll(/<\/*mark>/g, "");
        //if (item.infoEng) item.infoEng = item.infoEng.replaceAll(/<\/*mark>/g, "");
      });
      if (this.searchInput) {
        const search = this.searchInput.toLowerCase();
        filteredRosSotrInfo = filteredRosSotrInfo.filter((item) => {
          return (
            item.country?.toLowerCase().indexOf(search) !== -1 ||
            item.info?.toLowerCase().indexOf(search) !== -1
            //||
            //item.countryEng?.toLowerCase().indexOf(search) !== -1 ||
            //item.infoEng?.toLowerCase().indexOf(search) !== -1
          );
        });

        const searchTextRegExp = new RegExp(this.searchInput, "gi");
        filteredRosSotrInfo.forEach(item => {
          item.country = item.country?.replaceAll(searchTextRegExp, `<mark>${this.searchInput}</mark>`);
          item.info = item.info?.replaceAll(searchTextRegExp, `<mark>${this.searchInput}</mark>`);
          //item.countryEng = item.countryEng?.replaceAll(searchTextRegExp, `<mark>${this.searchInput}</mark>`);
          //item.infoEng = item.infoEng?.replaceAll(searchTextRegExp, `<mark>${this.searchInput}</mark>`);
        });
        return filteredRosSotrInfo;
      } else {
        return rosSotrInfo;
      }
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "200";
      options.width = "850";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.rossotr {

  @media (max-width: $mobile-version) and (orientation: portrait) {
    display: none;
  }

  &__title {
    min-width: 600px;
    margin-bottom: 42px;
    color: $primary-color;
    font-size: 24px;
    font-weight: 800;
  }

  &__table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid $grey-color;
    border-bottom: none;

    &-no-data {
      width: 100%;
      margin: 0 auto;
      padding: 10px 0;
      color: $primary-color;
      font-size: 24px;
      text-align: center;
      border-bottom: 1px solid $grey-color;
    }

    &-head {
      color: $primary-color;
      font-size: $fontSizeL;
      font-weight: 700;
      text-transform: uppercase;
    }

    &-row {
      width: 100%;

      &-flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid $grey-color;
      }
    }

    &-cell {
      &-country, &-info {
        min-height: 82px;
        padding: 15px 30px;
        font-size: $fontSizeL;
        text-align: center;

      }

      &-country {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        flex-basis: 350px;
        flex-shrink: 0;
      }

      &-info {
        flex-grow: 1;
        border-left: 1px solid $grey-color;
      }
    }
  }
}

.admin {

  &-input {
    width: 100%;

    > textarea {
      width: 300px;
      padding: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>