<template>
  <div class="block">
    <div class="block__header" @click="toggleBlock">
      <div :class="showDescription ? 'block__opened-number' : ''" class="block__number">
        {{ getBlockNumber }}
      </div>

      <div v-if="!editItem"
           :class="showDescription ? 'block__opened-title' : ''"
           class="block__title"
      >
        <span v-if="isEnglish">{{ infoBlock.titleEng }}</span>
        <span v-else>{{ infoBlock.title }}</span>
      </div>

      <div
        :style="{transform: showDescription ? 'rotate(-46deg)' : ''}"
        class="block__btn-toggle"
      >
        <svg
          height="26"
          viewBox="0 0 27 26"
          width="27"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path :class="showDescription ? 'block__opened-arrow' : ''"
                d="M26.0368 3.08396C26.6355 2.51134 26.6566 1.56182 26.084 0.963167C25.5113 0.364512 24.5618 0.34341 23.9632 0.916038L26.0368 3.08396ZM0.500368 23.9667C0.481962 24.7949 1.13845 25.4812 1.96667 25.4996L15.4633 25.7996C16.2916 25.818 16.9779 25.1615 16.9963 24.3333C17.0147 23.505 16.3582 22.8187 15.53 22.8003L3.53295 22.5337L3.79956 10.5367C3.81796 9.70843 3.16147 9.02211 2.33325 9.0037C1.50503 8.9853 0.8187 9.64178 0.800296 10.47L0.500368 23.9667ZM23.9632 0.916038L0.963163 22.916L3.03683 25.084L26.0368 3.08396L23.9632 0.916038Z"
          />
        </svg>
      </div>
    </div>
    <div v-if="checkContentManagerStatus">Клики: <span>{{ viewsCounter }}</span>
    </div>
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus"
      :item-edited="editItem"
      :item-id="infoBlock.id"
      :options="widgetOptionsDeleteBlock"
      @toggleEdit="editItem = !editItem"
    ></admin-widget-edit-delete>

    <div v-if="editItem" class="admin-input">
      <textarea v-model="infoBlock.titleEng" rows="2" style="margin-left: 80px"
                v-if="isEnglish"></textarea>
      <textarea v-model="infoBlock.title" rows="2" style="margin-left: 80px" v-else></textarea>
      <div style="margin-left: 80px">
        <editor
          v-model="infoBlock.descriptionEng"
          :apiKey="getEditorKey"
          :init="getEditorOptions"
          class="widget-input-editor"
          v-if="isEnglish"
        />
        <editor
          v-model="infoBlock.description"
          :apiKey="getEditorKey"
          :init="getEditorOptions"
          class="widget-input-editor"
          v-else
        />
      </div>
    </div>
    <div v-else
         :style="{display: showDescription ? 'block' : 'none'}"
         class="block__description"
         @click="setBlockId"
         v-html="isEnglish ? infoBlock.descriptionEng : infoBlock.description"
    ></div>
    <div v-if="showModal" class="block__modal" >
      <div class="modal-wrapper" ref="modal-wrapper" @click.self="closeModal">
      <div class="block__modal-box">
        <admin-widget-edit-delete
          v-if="checkContentManagerStatus"
          :item-edited="editModal"
          :options="widgetOptionsEditModal"
          style="margin-right: 40px"
          @toggleEdit="editModal = !editModal"
        />
        <div v-if="editModal" class="admin-input">
          <textarea v-model="getModalText.title" rows="1"></textarea>
          <editor
            v-model="getModalText.text"
            :apiKey="getEditorKey"
            :init="getEditorOptions"
            class="widget-input-editor"
          />
        </div>
        <div v-else>
          <div v-if="!getModalText">
            <admin-widget-add
              v-if="checkContentManagerStatus"
              :modalId="getModalHash[1]"
              :options="widgetOptionsAdd"
              style="margin-left: -70px; width: 120%; padding: 0"
            />
          </div>
          <div v-else>
            <div class="block__modal-title">{{ getModalText.title }}</div>
            <div class="block__modal-text" v-html="getModalText.text"></div>
          </div>
        </div>
        <router-link :to="getCurrentPath">
          <img
            class="block__modal-icon-close"
            src="@/assets/images/icon-close.svg"
          />
        </router-link>
      </div >
      </div>
    </div>
  </div>
</template>

<script>
import AdminWidgetEditDelete from "@/components/admin/AdminWidgetEditDelete";
import Editor from "@tinymce/tinymce-vue";
import AdminWidgetAdd from "@/components/admin/AdminWidgetAdd";
import request from "@/services/request";

export default {
  name: "ToggleNumberBlockListItem",
  components: {
    AdminWidgetAdd,
    AdminWidgetEditDelete,
    Editor
  },
  props: {
    infoBlock: {
      type: Object,
      default: () => {
      }
    },
    counter: {
      type: Object,
      default: () => {
      }
    },
    blockNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      clickedBlockId: null,
      newShowModal: false,
      newModal: {},
      showDescription: false,
      editItem: false,
      editModal: false,
      scrollY: "",
      scrollX: "",
      widgetOptionsDeleteBlock: {
        mutationName: "deleteToggleNumberBlockItem",
        swapItems: {
          mutationNames: ["moveToggleNumberBlockItemUp", "moveToggleNumberBlockItemDown"],
          type: "upDown"
        }
      },
      widgetOptionsEditModal: {
        mutationName: "",
        swapItems: {
          mutationNames: [],
          type: ""
        }
      },
      widgetOptionsAdd: {
        title: "Добавить текст",
        mutationName: "addModalText",
        inputs: [
          {
            label: "Заголовок",
            numberOfRows: 1,
            vModelKeyName: "title"
          },
          {
            label: "Текст",
            numberOfRows: 5,
            vModelKeyName: "text",
            editorEnable: true,
            editorHeight: "300"
          }
        ],
        parentId: this.infoBlock.id,
        editorWidth: "600"
      },
    };
  },
  mounted() {
    window.onscroll = () => {
      this.scrollY = window.scrollY;
      this.scrollX = window.scrollX;
    };
    // let vm = this;
    // document.addEventListener('click', function(item) {
    //   if (item.target === vm.$refs['modal-wrapper']) {
    //     vm.close()
    //     console.log(123)
    //   }
    // })
  },
  watch: {
    "$route.path"() {
      this.showDescription = false;
    },
    getModalHash(v){
      if (v)
        this.openModal()
    }
  },
  computed: {
    getCurrentPath() {
      return this.$route.path;
    },
    isEnglish() {
      return this.$store.getters.checkEnglishVersion;
    },
    getBlockNumber() {
      let number = this.blockNumber + 1;
      if (number < 10) {
        return "0" + number;
      } else {
        return number;
      }
    },
    getModalHash() {
      if (this.$route.hash) {
        return this.$route.hash.slice(1).split("-").map(item => Number(item));
      } else {
        return null;
      }
    },
    showModal() {
      window.scrollTo(0, this.scrollY);
      if (this.getModalHash) {
        return (this.infoBlock.id + 1) === this.getModalHash[0];
      } else {
        return false;
      }
    },
    getModalText() {
      const selectedBlock = this.infoBlock.modals.filter(modal => {
        //console.log(modal.id);
        return modal.id === this.getModalHash[1];
      });
      //console.log(selectedBlock);
      return selectedBlock[0];
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "500";
      options.width = "950";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    },
    viewsCounter() {
      if (this.counter) {
        return this.counter.viewsCount
      } else {
        return 0
      }
    }
  },
  methods: {
    setBlockId() {
      this.clickedBlockId = this.infoBlock.id;
    },
    openModal(){
      this.newShowModal = !this.newShowModal
    },
    closeModal(){
    this.$router.push(this.getCurrentPath)
    },
    async toggleBlock() {
      if (this.showDescription) {
        this.showDescription = false;
      } else {
        this.showDescription = true;
        // Увеличивает счетчик клика по блоку
        await request({
          endpoint: `/api/category`,
          method: "POST",
          body: {
            id: this.infoBlock.id,
            path: this.getCurrentPath.slice(1),
            title: this.infoBlock.title
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.modal-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
}
.block {
  min-width: 1100px;
  position: relative;
  margin-bottom: 15px;
  padding: 14px 85px 14px 19px;
  background-color: $white-color;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;

  //@media (max-width: $mobile-version) and (orientation: portrait) {
  //  margin-bottom: 5px;
  //  padding: 14px 10px;
  //  box-shadow: $boxShadowMobile;
  //  border-radius: $borderRadiusMobile;
  //}

  &:hover &__number {
    color: $primary-color;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  color: $grey-color;
    //}
  }

  &:hover &__btn-toggle {
    transform: translate(-7px, 7px);

    > svg > path {
      fill: $primary-color;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &__number {
    color: $grey-color;
    width: 80px;
    flex-shrink: 0;
    font-size: 48px;
    font-weight: 800;
    transition: all 0.2s linear;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  width: 40px;
    //  font-size: 24px;
    //}
  }

  &__title {
    font-weight: 500;
    font-size: $fontSizeL;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  font-size: $fontSizeMobileM;
    //  line-height: 13px;
    //}
  }

  &__btn-toggle {
    position: absolute;
    right: 35px;
    margin-left: 20px;
    transition: 0.2s;

    //@media (max-width: $mobile-version) and (orientation: portrait) {
    //  display: none;
    //}

    > svg {
      fill: $grey-color;
    }
  }

  &__description {
    padding: 20px 10px 20px 80px;
    font-size: $fontSizeS;
  }

  &__modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;

    &-box {
      position: relative;
      width: 730px;
      max-height: 80vh;
      margin: 0 auto;
      padding: 40px 30px 30px;
      background-color: $white-color;
      border-radius: $borderRadiusMobile;
      overflow-y: auto;
      overflow-x: auto;
      overscroll-behavior-y: none;
      z-index: 250;

      //@media (max-width: $mobile-version) and (orientation: portrait) {
      //  width: 275px;
      //  padding: 12px 10px;
      //  border-radius: $borderRadiusMobile;
      //}
    }

    &-icon-close {
      position: absolute;
      top: 20px;
      right: 30px;
      cursor: pointer;

      //@media (max-width: $mobile-version) and (orientation: portrait) {
      //  width: 12px;
      //  top: 12px;
      //  right: 15px;
      //}
    }

    &-title {
      margin-bottom: 10px;
      font-weight: 700;
    }

    &-text {
      font-size: $fontSizeS;
    }
  }

  &__opened {
    &-number {
      color: $primary-color;
    }

    &-title {
      color: $primary-color;
      font-size: 24px;

      @media (max-width: $mobile-version) {
        font-size: 20px;
      }
    }

    &-arrow {
      fill: $primary-color;
    }
  }
}

.block:hover {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.admin {

  &-input {
    max-width: 100%;
    width: 950px;
    padding-top: 20px;

    > textarea {
      width: 100%;
      margin-bottom: 5px;
      padding: 10px;
      background-color: $white-color;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}
</style>