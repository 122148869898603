<template>
  <div>
    <admin-widget-edit-delete
      v-if="checkContentManagerStatus"
      :item-edited="editItem"
      :item-id="eventData.id"
      :options="widgetOptions"
      style="position: relative; left: -20px"
      @toggleEdit="editItem = !editItem" />
    <div v-if="editItem">
      <div class="admin-input">
        <select v-model="eventData.title">
          <option v-for="(type, index) in ['Вебинар', 'Конференция', 'Семинар']"
                  :key="index"
                  :value="type">
            {{ type }}
          </option>
        </select>
      </div>
      <div class="admin-input">
        <editor
          v-model="eventData.text"
          :apiKey="getEditorKey"
          :init="getEditorOptions"
        />
      </div>
      <div class="admin-input">
        <date-picker
          v-if="editItem"
          v-model="eventData.datetime"
          :format="datePickerOptions.format"
          :lang="datePickerOptions.lang"
          :value-type="datePickerOptions.valueType"
          class="widget-input-datepicker"
          type="datetime"
        />
      </div>
    </div>
    <router-link v-else :to="/events/ + eventData.id" class="events__item">
      <div class="events__item-info">
        <div :style="{color: titleColor}" class="events__item-title">
          <img :alt="eventData.title" :src="imageSource" class="events__item-icon" />
          <span>{{ eventData.title }}</span>
        </div>
        <div class="events__item-text" v-html="eventData.text"></div>
      </div>
      <div class="events__item-params">
        <div class="events__item-time">{{ eventTime }} (мск)</div>
        <div class="events__item-date">{{ eventDate }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
import moment from "moment";
import AdminWidgetEditDelete from "../admin/AdminWidgetEditDelete";
import Editor from "@tinymce/tinymce-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "EventListItem",
  components: {
    AdminWidgetEditDelete,
    Editor,
    DatePicker
  },
  props: {
    eventData: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      editItem: false,
      widgetOptions: {
        mutationName: "deleteEventItem",
        swapItems: ""
      },
      datePickerOptions: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1
          },
          monthBeforeYear: false
        },
        format: "DD.MM.YYYY HH:mm",
        valueType: "timestamp"
      }
    };
  },
  computed: {
    titleColor() {
      switch (this.eventData.title) {
        case "Вебинар":
          return "#008BCB";
        case "Конференция":
          return "#EF3762";
        default:
          return "#FFC014";
      }
    },
    imageSource() {
      let imageName;
      switch (this.eventData.title) {
        case "Вебинар":
          imageName = "webinar";
          break;
        case "Конференция":
          imageName = "conference";
          break;
        default:
          imageName = "seminar";
      }
      return require(`@/assets/images/icon-${imageName}.svg`);
    },
    // Вариант свойства для обрезки текста больше определенного количества слов
    // eventCroppedText() {
    //   return this.eventData.text.split(' ').slice(0, 25).join(' ') + '...'
    // },
    eventDate() {
      return moment(this.eventData.datetime).locale("ru").format("L");
    },
    eventTime() {
      return moment(this.eventData.datetime).utcOffset(3).locale("ru").format("LT");
    },
    getEditorOptions() {
      let options = this.$store.getters.getEditorOptions;
      options.height = "300";
      options.width = "500";
      return options;
    },
    getEditorKey() {
      return this.$store.getters.getEditorKey;
    },
    checkContentManagerStatus() {
      return this.$store.getters.getContentManagerStatus;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.events {
  &__item {
    height: 310px;
    width: 525px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 35px 27px;
    color: $black-color;
    font-weight: 700;
    background-color: $white-color;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    cursor: pointer;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      width: 260px;
      height: 200px;
      padding: 12px 19px;
      color: $primary-color;
      font-size: $fontSizeMobileL;
      font-weight: 800;
      background-size: 300px;
      background-position: -15px -5px;
      border-radius: $borderRadiusMobile;
      box-shadow: $boxShadowMobile;
    }

    &:hover {
      box-shadow: $boxShadowHover;
    }

    &-title {
      height: 60px;
      display: flex;
      align-items: center;
      margin-bottom: 38px;
      font-size: 32px;
      font-weight: 700;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 700;
      }

      > img {
        margin-right: 15px;

        @media (max-width: $mobile-version) and (orientation: portrait) {
         width: 35px;
        }
      }
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow-y: hidden;

      @media (max-width: $mobile-version) and (orientation: portrait) {
       font-size: $fontSizeMobileS;
        -webkit-line-clamp: 5;
      }
    }

    &-params {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: $fontSizeL;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: $fontSizeMobileM;
      }
    }

    &-time {
      align-self: flex-end;
      color: $grey-color;
    }

    &-date {
      align-self: flex-end;
      color: $grey-color;
    }
  }
}

.admin {

  &-input {
    display: inline-block;
    max-width: 100%;
    width: 400px;
    margin-bottom: 5px;
    font-size: 14px;
    z-index: 600;

    > select {
      width: 100%;
      padding: 10px 5px;
      border: 1px solid $black-color;
    }

    > textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid $black-color;
      resize: vertical;
    }
  }
}

</style>