<template>
  <router-link :to="card.link" class="card">
    <div class="card__title">
      <span v-if="isEnglish">{{ card.titleEng }}</span>
      <span v-else>{{ card.title }}</span>
    </div>
    <div class="card__image"><img @load="imageLoaded(card.id)" :src="require(`@/assets/images/${card.image}.svg`)" alt="Иконка карточки" /></div>
  </router-link>
</template>

<script>
export default {
  name: "CardListItem",
  props: {
    card: {
      type: Object,
      default: () => []
    }
  },
  methods: {
    imageLoaded(id) {
      this.$emit('checkImageLoaded', {id})
    }
  },
  computed: {
    isEnglish() {
      return this.$store.getters.checkEnglishVersion
    },
  }
};
</script>


<style lang="scss" scoped>
@import "src/assets/styles/variables";

.card {
  min-width: 360px;
  max-width: 525px;
  height: 310px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 30px 30px;
  color: $black-color;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: $borderRadius;
  box-shadow: $boxShadow;
  cursor: pointer;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    min-width: 260px;
    max-width: 260px;
    height: 175px;
    padding: 14px 19px;
    border-radius: $borderRadiusMobile;
    box-shadow: $boxShadowMobile;
  }

  &:hover {
    box-shadow: $boxShadowHover;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      box-shadow: $boxShadowMobile;
    }
  }

  &:hover &__title {
    color: $primary-color;
  }

  &__title {
    width: 100%;
    align-self: flex-start;
    font-size: $fontSizeL;
    font-weight: 700;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      color: $primary-color;
      font-size: $fontSizeMobileL;
      font-weight: 800;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;

    > img {
      width: 105%;
      display: block;
      margin: 0 auto;

      @media (max-width: $mobile-version) and (orientation: portrait) {
        font-size: $fontSizeMobileL;
      }
    }
  }

}
</style>